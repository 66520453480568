import React, { useState } from 'react'
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, useDisclosure } from '@chakra-ui/react'
import { Edit } from 'react-feather'

import { tzSafeDateFormat } from 'helpers/utils'

import { pickStyleMapping } from 'domain/Widgets/Logbook/constants'
import { TableEditModal } from 'domain/Widgets/Logbook/GoalComponents/TableEditModal'

export const GoalLogbookEntriesTable = ({ logbook }) => {
  const styleMapping = pickStyleMapping(logbook)
  const { entries } = logbook

  const hoverProps = { cursor: 'pointer', color: 'brand.500' }

  const [editEntryId, setEditEntryId] = useState(null)
  const { isOpen: isEditing, onToggle: onEditingToggle } = useDisclosure()

  const handleEditClick = ({ logbookEntryId }) => {
    setEditEntryId(logbookEntryId)
    onEditingToggle()
  }

  return (
    <Box background="white" p="4">
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th isNumeric></Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {entries.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>{tzSafeDateFormat(item.date, 'd MMM yyyy')}</Td>
                <Td color="gray.600">{styleMapping[item.style]}</Td>
                <Td isNumeric>{item.attempts}</Td>
                <Td>{item.notes}</Td>
                <Td title="Edit entry">
                  <Text
                    onClick={() => {
                      handleEditClick({
                        logbookEntryId: item.id,
                      })
                    }}
                    _hover={hoverProps}
                  >
                    <Edit size="16px" />
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {isEditing && (
        <TableEditModal id={editEntryId} isOpen={isEditing} closeModal={onEditingToggle} />
      )}
    </Box>
  )
}
