import React from 'react'
import { Box, Icon, Flex, Td } from '@chakra-ui/react'
import { MoreVertical as DragHandleIcon } from 'react-feather' // Menu

import TextOverflow from 'components/TextOverflow'

import { AddAttemptButton, EditButton, ProjectGoalButton, LogbookButtons } from './'
import { tableWidths, pickStyleMapping } from 'domain/Widgets/Logbook/constants'

const ContentRow = ({
  provided,
  snapshot,
  entry,
  handleAddAttempt,
  handleCreateProjectGoalFromEntry,
  handleEditEntry,
  logbook,
  showEditButton,
}) => {
  const showAttemptStyles = ['attempt', 'work']
  const showAttemptButton = showEditButton && showAttemptStyles.includes(entry.style)
  const showProjectGoalButton = showAttemptButton && !entry.project
  const styleMapping = pickStyleMapping(logbook)

  const showDragHandle = showEditButton
  const enableDragHandle = showEditButton && logbook.entries.length > 1

  const { isDragging } = snapshot
  const border = isDragging && 0

  // Specifying both maxWidth and width when isDragging
  // ensures the row holds its shape at all times
  // If these fields (name / notes) are empty
  // then when dragging their width will collapse

  const handleCreateProjectGoalClick = (entry) => {
    handleCreateProjectGoalFromEntry(entry)
  }

  return (
    <>
      <TdE
        name="name"
        maxWidth={tableWidths.name}
        {...{ showDragHandle, enableDragHandle, provided, border }}
      >
        <TextOverflow>{entry.name}</TextOverflow>
      </TdE>
      <TdE name="grade_id" {...{ showDragHandle, enableDragHandle, provided, border }}>
        {entry.grade}
      </TdE>
      <TdE name="style" {...{ showDragHandle, enableDragHandle, provided, border }}>
        {styleMapping[entry.style]}
      </TdE>
      <TdE name="attempts" {...{ showDragHandle, enableDragHandle, provided, border }}>
        {entry.attempts || <Box style={{ visibility: 'hidden' }}>&nbsp;</Box>}
      </TdE>
      <TdE
        name="notes"
        maxWidth={tableWidths.notes}
        {...{ showDragHandle, enableDragHandle, provided, border }}
      >
        <TextOverflow>{entry.notes}</TextOverflow>
      </TdE>
      <Td
        border={border}
        px={0}
        width={tableWidths.buttons}
        maxWidth={tableWidths.buttons}
        padding={0}
      >
        <LogbookButtons flexDirection="row-reverse">
          {showEditButton && <EditButton onClick={() => handleEditEntry(entry)} />}
          {showAttemptButton && <AddAttemptButton onClick={() => handleAddAttempt(entry)} />}
          {showProjectGoalButton && (
            <ProjectGoalButton onClick={() => handleCreateProjectGoalClick(entry)} />
          )}
        </LogbookButtons>
      </Td>
    </>
  )
}

// When editing a row, the dragHandleProps still need to be present otherwise an error like this happens:
//
// A setup problem was encountered.
// > Invariant failed: Draggable[id: 00c0a1e4-3d2c-410d-82cd-545bbb215b51]: Unable to find drag handle

// But we don't want the user to be able to actually drag something when editing, so it needs to be hidden from view
// We just have a Box there with the dragHandleProps at all times with display: none
// i.e.
// <Box style={{ display: 'none' }} {...provided.dragHandleProps}></Box>

const TdE = ({ name, showDragHandle, enableDragHandle, provided, border, children, ...props }) => {
  return (
    <Td
      width={tableWidths[name]}
      border={border}
      {...(showDragHandle && enableDragHandle && provided.dragHandleProps)}
      {...props}
    >
      {enableDragHandle ? (
        <Box {...provided.dragHandleProps} pl={0} pr={1}>
          {children}
        </Box>
      ) : (
        <>
          <Box style={{ display: 'none' }} {...provided.dragHandleProps}></Box>
          <Box pl={0} pr={1}>
            {children}
          </Box>
        </>
      )}
    </Td>
  )
}

export default ContentRow
