import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  Flex,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import { useQuery } from 'hooks'

import LastXDaysStatic from 'components/General/LastXDaysStatic'
import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'

const AllClientComments = () => {
  const days = 7

  const { data, isLoading, hasError } = useQuery(`api/v1/coaching/recent_comments?days=${days}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Card rounded="0">
      <CardHeader paddingBottom="2" borderBottom="1px solid #e2e8f0">
        <Heading size="sm">Recent comments</Heading>
        <LastXDaysStatic days={days} />
      </CardHeader>
      <CardBody maxHeight="500px" overflowY="auto">
        <Stack divider={<StackDivider />} spacing="4">
          {data.length > 0 ? (
            data.map((workout) => (
              <Stack key={workout.id} fontSize="sm" px="1" spacing="2">
                <Stack direction="row" justify="space-between" spacing="4">
                  <Box>
                    <Link
                      as={NavLink}
                      to={`/coaching/client/${workout.coaching_connection_id}`}
                      fontWeight="medium"
                      color="gray.600"
                      _hover={{ color: 'brand.500', cursor: 'pointer' }}
                    >
                      {workout.display_name || workout.client_name}
                    </Link>
                  </Box>

                  <Text color="gray.500">{workout.time_ago}</Text>
                </Stack>
                <Stack spacing="1">
                  <Flex>
                    <Box
                      bgColor={workout.category_hex_colour}
                      tooltip={workout.category_name}
                      width={1}
                      mr="1"
                    />
                    <Text color="gray.600">{workout.activity_name}</Text>
                  </Flex>
                  <Text color="gray.500">{workout.notes}</Text>
                </Stack>
              </Stack>
            ))
          ) : (
            <Text fontSize="sm" color="gray.500">
              No comments for selected period
            </Text>
          )}
        </Stack>
      </CardBody>
    </Card>
  )
}

export default AllClientComments
