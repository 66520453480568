import React, { useEffect, useState } from 'react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processApiError, reorder } from 'helpers/utils'
import { useActivityModal } from 'hooks'

import { hideAttemptsStyles } from 'domain/Widgets/Logbook/constants'

const useLogbookMethods = (methods) => {
  const [isAdding, setIsAdding] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [editId, setEditId] = useState(null)

  const [isNameFocused, setIsNameFocused] = useState(false)
  const [isNotesFocused, setIsNotesFocused] = useState(false)

  const isEditing = editId !== null
  const isFormShowing = isEditing || isAdding
  const showEditButton = !isFormShowing

  const preventActivityModalClose = useActivityModal((state) => state.preventActivityModalClose)
  const setPreventActivityModalClose = useActivityModal(
    (state) => state.setPreventActivityModalClose
  )

  useEffect(() => {
    setPreventActivityModalClose(isFormShowing)
  }, [isFormShowing, setPreventActivityModalClose])

  const handleAddAttempt = (entry) => {
    setIsNotesFocused(true)

    setIsAdding(true)

    const { name, grade, grade_id, style, flags } = entry
    const { setValue } = methods

    setValue('name', name)
    setValue('grade_id', grade_id)
    setValue('grade', grade)
    setValue('style', style)
    setValue('flags', flags)
  }

  const handleAddEntry = () => {
    setIsNameFocused(true)
    setIsNotesFocused(false)

    setIsAdding(true)
  }

  const handleCancelEdit = () => {
    setEditId(null)

    // otherwise data lags one cycle
    // when editing then discarding edits after clicking on next edit button
    methods.reset()
  }

  const handleCreateProjectGoalFromEntry = (entry) => {
    securedApi
      .put(`api/v1/widget_logbook_entries/${entry.id}/create_goal`)
      .then(({ data }) => {
        window.location.assign(`/goals/${data.id}`)
        Notify({ content: 'Goal created', type: 'success' })
      })
      .catch((error) => {
        processApiError(error)
      })
      .finally(() => {})
  }

  const handleEditEntry = (entry) => {
    setIsNameFocused(false)
    setIsNotesFocused(true)

    setEditId(entry.id)
  }

  // for these 2 functions, if methods.reset() is not present,
  // then after discarding the (new / editing) entry,
  // the next time the form is displayed (new / editing)
  // it will contain whatever values were discarded instead of correct (empty / entry) values
  const handleDiscardEditEntry = () => {
    setEditId(null)
    methods.reset()
  }

  const handleDiscardNewEntry = () => {
    setIsAdding(false)
    methods.reset()
  }

  const handleDeleteLogbookEntry = ({ logbooks, onSetLogbooks, logbook, entry }) => {
    securedApi
      .delete(`api/v1/widget_logbook_entries/${entry.id}`)
      .then((response) => {
        const newState = logbooks
        const logbookIndex = logbooks.findIndex((x) => x.id === logbook.id)
        newState[logbookIndex].entries = newState[logbookIndex].entries.filter(
          (x) => x.id !== entry.id
        )

        setEditId(null)

        // otherwise next time form is display it will container the values
        // of the deleted entry instead of correct values
        methods.reset()
      })
      .catch((error) => processApiError(error))
  }

  const handleMoveLogbookEntry = ({
    logbooks,
    onSetLogbooks,
    logbookId,
    entryId,
    originalPosition,
    reorderedPosition,
  }) => {
    // optimistically update the UI before sending the API call

    const newState = logbooks
    const logbookIndex = logbooks.findIndex((x) => x.id === logbookId)

    newState[logbookIndex].entries = reorder(
      newState[logbookIndex].entries,
      originalPosition,
      reorderedPosition
    )

    onSetLogbooks(newState)
    // setIsMoving(false)

    securedApi
      .put(`api/v1/widget_logbook_entries/${entryId}/reorder`, {
        pos: reorderedPosition,
      })
      .then((response) => {
        // nothing to do if successful
      })
      .catch((error) => {
        processApiError(error, 'Error moving logbook entry', function () {
          // TODO undo the reorder in the UI
        })
      })
  }

  const handleSubmitLogbookEntry = ({
    scheduledActivityId,
    logbooks,
    logbook,
    onSetLogbooks,
    entry,
  }) => {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    const commonParams = {
      scheduled_activity_id: scheduledActivityId,
      widget_logbook_id: logbook.id,
    }

    const isNewEntry = entry.id === ''

    if (hideAttemptsStyles.includes(entry.style)) {
      entry.attempts = null
    }

    securedApi
      .put(`api/v1/widget_logbook_entries/control`, {
        id: isNewEntry ? null : entry.id,
        widget_logbook_entry: { ...entry, ...commonParams },
      })
      .then(({ data }) => {
        const newState = logbooks

        // update local state with id and content if entry was created
        const logbookIndex = logbooks.findIndex((x) => x.id === logbook.id)

        const entryIndex = isNewEntry
          ? logbooks[logbookIndex].entries.length // i.e. last index + 1
          : logbooks[logbookIndex].entries.findIndex((x) => x.id === entry.id)

        // if create then id will now be present
        newState[logbookIndex].entries[entryIndex] = data

        onSetLogbooks(newState)

        setIsAdding(false)
        setIsSaving(false)
        setEditId(null)

        // needed so these values don't carry over to
        // the next clicked entry row in the table
        methods.reset()
      })
      .catch((error) => {
        processApiError(error)
      })
  }

  return {
    isAdding,
    setIsAdding,
    editId,
    setEditId,
    isEditing,
    showEditButton,
    // focus fields
    isNameFocused,
    isNotesFocused,
    // handle methods
    handleAddAttempt,
    handleAddEntry,
    handleCancelEdit,
    handleCreateProjectGoalFromEntry,
    handleEditEntry,
    handleDeleteLogbookEntry,
    handleDiscardNewEntry,
    handleDiscardEditEntry,
    handleMoveLogbookEntry,
    handleSubmitLogbookEntry,
  }
}

export default useLogbookMethods

// const handleMove = (entry, direction) => {
//   const delta = direction === 'up' ? -1 : 1
//   const endpoint = `api/v1/widget_logbook_entries/${entry.id}/reorder`
//   const payload = {
//     pos: direction,
//   }

//   const logbookIndex = 0 // TODO

//   const logbooksNew = logbooks
//   const entries = logbooksNew[logbookIndex].entries

//   const idxOld = entries.findIndex((i) => i.id === entry.id)
//   const idxNew = idxOld + delta

//   const entriesNew = [...entries]
//   const field = entriesNew.splice(idxOld, 1)[0]

//   entriesNew.splice(idxNew, 0, field)
//   logbooksNew[logbookIndex].entries = entriesNew

//   securedApi
//     .put(endpoint, payload)
//     .then(() => {
//       onSetLogbooks(logbooksNew)
//       setForceRerender(true)
//     })
//     .catch((error) => {
//       processApiError(error, "Can't move entry")
//     })
// }

// const handleMoveUp = (entry) => {
//   handleMove(entry, 'up')
// }

// const handleMoveDown = (entry) => {
//   handleMove(entry, 'down')
// }
