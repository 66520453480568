import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  UnorderedList,
  ListItem,
  Stack,
  Select,
  Text,
} from '@chakra-ui/react'

import { LOGBOOK_TYPE } from 'domain/Widgets/constants'

import { EditWidgetModalActions, FieldErrorText } from 'domain/Widgets/components'

const LogbookFormFields = ({
  widget,
  activityId,
  onCreateWidget,
  onUpdateWidget,
  onDeleteWidget,
  onEditWidgetClose,
}) => {
  const isEditingActivity = !!activityId
  const isEditingOrNewWidget = isEditingActivity || !widget.new

  const {
    control,
    register,
    getValues,
    clearErrors,
    setError,
    trigger,
    formState: { errors },
  } = useFormContext()

  // Need to create form fields with unique id because a workout can have many widgets
  // and we don't want a clash for the same field with different widgets
  const name = `${widget.id}_logbook_name`
  const grade_scale = `${widget.id}_logbook_grade_scale`
  const entry_flag_types = `${widget.id}_entry_flag_types`

  // If the widget has been created then we need to populate the form field
  const defaultNameValue = isEditingOrNewWidget ? widget?.content?.name : null
  const defaultGradeScaleValue = isEditingOrNewWidget ? widget?.content?.grade_scale : null
  const defaultEntryFlagTypes = isEditingOrNewWidget ? widget?.content?.entry_flag_types : null

  const isRouteLogbook = defaultNameValue === 'Route'

  const handleChange = () => {
    const editWidgetData = {
      ...widget,
      content: {
        name: getValues(name),
        grade_scale: getValues(grade_scale),
        entry_flag_types: getValues(entry_flag_types),
      },
    }

    if (!getValues(name)) {
      trigger(name, { shouldFocus: true })
      return
    }

    if (!getValues(grade_scale)) {
      trigger(grade_scale, { shouldFocus: true })
      return
    }

    if (!getValues(entry_flag_types)) {
      trigger(entry_flag_types, { shouldFocus: true })
      return
    }

    if (widget.new) {
      onCreateWidget({ type: LOGBOOK_TYPE, widgetData: editWidgetData })
      onEditWidgetClose()
    } else {
      onUpdateWidget(editWidgetData)
      onEditWidgetClose()
    }
  }

  const renderGradeScaleOptions = () => {
    const placeholder = <option value="">Select a grade scale</option>
    if (isRouteLogbook) {
      return (
        <>
          {placeholder}
          <option value="aus">Ewbank</option>
          <option value="french">French</option>
          <option value="yds">YDS</option>
        </>
      )
    } else {
      return (
        <>
          {placeholder}
          <option value="hueco">Hueco</option>
          <option value="font">Font</option>
        </>
      )
    }
  }

  const renderEntryFlagTypeOptions = () => {
    if (isRouteLogbook) {
      const flagTypesRoute = [
        { name: 'Repeat', description: 'Repeat of a route already sent' },
        { name: 'Top rope', description: null },
        { name: 'Second', description: null },
        { name: 'Trad', description: 'Traditional climb' },
        {
          name: 'Exclude',
          description: 'Exclude this ascent from metrics (e.g. draw retrieval)',
        },
      ]

      return (
        <Flex flexDirection="column">
          {flagTypesRoute.map(({ name, description }) => (
            <Flex>
              <Checkbox
                key={name}
                name={name}
                width="8em"
                {...register(`${entry_flag_types}.${name}`)}
              >
                {name}
              </Checkbox>
              <Text fontStyle="italic">{description}</Text>
            </Flex>
          ))}
        </Flex>
      )
    } else {
      const flagTypesBoulder = ['Repeat']

      return (
        <Flex flexDirection="column">
          {flagTypesBoulder.map((flagType) => (
            <Checkbox key={flagType} name={flagType}>
              {flagType}
            </Checkbox>
          ))}
        </Flex>
      )
    }
  }

  const attemptText = isRouteLogbook ? 'redpoint the route' : 'tick the boulder'
  const workText = isRouteLogbook
    ? 'working moves or a section of the route'
    : 'working moves on the boulder'

  const styleTickOrRedpointText = isRouteLogbook ? 'Redpoint' : 'Tick'

  return (
    <Box>
      <Stack>
        <FormControl>
          <FormLabel mb="0">Type of Logbook</FormLabel>
          <Box p={4}>
            <Text>{defaultNameValue}</Text>
          </Box>
          <Input
            type="hidden"
            defaultValue={defaultNameValue}
            {...register(name, {
              required: "Name required, can't be blank",
            })}
          />
          <FieldErrorText errors={errors} field={name} />
        </FormControl>
        <FormControl>
          <FormLabel mb="2">Grade Scale</FormLabel>
          <Text fontSize="sm" pl={5} pr={5}>
            Set the grade scale to choose from when adding an entry to your logbook. You can change
            it to log routes in a different grade scale (e.g. if going on a trip overseas, and
            change it back when you return), and it won't affect existing logbook entries in other
            grade scales.
          </Text>
          <Text fontSize="sm" pl={5} pr={5} pt={2}>
            The values in your metrics will also be converted to the selected grade scale.
          </Text>
          <Select
            pt={2}
            defaultValue={defaultGradeScaleValue}
            variant="outline"
            {...register(grade_scale, {
              required: "Grade Scale required, can't be blank",
            })}
          >
            {renderGradeScaleOptions()}
          </Select>
          <FieldErrorText errors={errors} field={grade_scale} />
        </FormControl>
        <FormControl>
          <FormLabel mt={2}>Tick Style</FormLabel>
          <Text fontSize="sm" pl={5} pr={5}>
            Each logbook has the standard ascent styles of <em>Onsight</em>, <em>Flash</em> and{' '}
            <em>{styleTickOrRedpointText}</em>. It also makes an important distinction between{' '}
            <em>Attempt</em> and <em>Work</em>:
          </Text>
          <UnorderedList ml={0} mt={1} mb={1} fontSize="sm" spacing={1}>
            <ListItem>
              <em>Attempt</em>: has the explicit intention to {attemptText}
            </ListItem>
            <ListItem>
              <em>Work</em>: the focus is just on {workText}
            </ListItem>
          </UnorderedList>

          <Text fontSize="sm" pl={5} pr={5} pt={2}>
            This allows you to track the effort required to accomplish these different tasks
          </Text>

          <Text fontSize="sm" pl={5} pr={5} pt={2}>
            An entry can have flags to help categorise the ascent. A typical flag is is repeat,
            which you can use for regular warmups at your local crag. These won't count towards
            stats for how long it takes to send routes for each grade, but you can view them to see
            volume.
          </Text>
          <Text pl={5} pr={5} pt={2}>
            Configure flag types to show
          </Text>
          <Box ml={5} mt={2}>
            {renderEntryFlagTypeOptions()}
          </Box>
          <Input
            pt={2}
            type="hidden"
            defaultValue={defaultEntryFlagTypes}
            variant="outline"
            {...register(entry_flag_types, {})}
          />
        </FormControl>
      </Stack>

      <EditWidgetModalActions {...{ widget, handleChange, onDeleteWidget, onEditWidgetClose }} />
    </Box>
  )
}

export default LogbookFormFields
