import { create } from 'zustand'

const useTemplateActivityModal = create((set) => ({
  templateActivityModalOpen: false,
  templateActivityModalWorkoutId: null,
  setTemplateActivityModalOpen: () => set(() => ({ templateActivityModalOpen: true })),
  setTemplateActivityModalClose: () => set(() => ({ templateActivityModalOpen: false })),
  setTemplateActivityModalWorkoutId: (value) =>
    set(() => ({ templateActivityModalWorkoutId: value })),
}))

export default useTemplateActivityModal
