import { Link } from 'react-router-dom'
import {
  Button,
  Box,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  HStack,
  Divider,
  FormControl,
  Input,
  Link as ChakraLink,
  Badge,
} from '@chakra-ui/react'

import Navigation from './components/Navigation'

import Hero from './assets/hero.png'
import TrainingHero from './assets/training-hero.jpg'
import GrooveHero from './assets/groove-hero.jpg'
import KerrinHero from './assets/kerrin-hero.jpg'

import { premium } from './data'

const links = [
  {
    name: 'Purchased Plans',
    url: '/plans/purchased-plans',
  },
]

const DefaultPlansPage = ({ hasPurchasedPlans }) => {
  return (
    <>
      {hasPurchasedPlans && <Navigation {...{ links }} />}

      <Image
        alt="Training Psych"
        src={Hero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'lg' }}
        width="full"
      />
      <Container pt={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing="3">
          <Heading size={{ base: 'md', md: 'xl' }}>Welcome to Sequence training plans.</Heading>
          <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
            Browse the latest plans to become a better climber.
          </Text>
        </Stack>
      </Container>
      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }}>
          <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Premium Plans
            </Text>
            <Divider />
          </HStack>
          <Stack spacing={{ base: '4', md: '5' }} maxW="2xl">
            <Heading size={{ base: 'sm', md: 'md' }}>What can you expect?</Heading>

            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              The best climbing training plans developed by industry experts delivered to you
              seamlessly on the awesome Sequence platform.
            </Text>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
            {premium.map((company) => (
              <Box
                key={company.name}
                bg="white"
                boxShadow="sm"
                borderRadius="lg"
                p={{ base: '4', md: '6' }}
              >
                <Stack spacing={{ base: '4', md: '5' }}>
                  <Stack
                    spacing={{ base: '1', md: '2' }}
                    flex="1"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Image
                      src={company.logo}
                      boxSize="150px"
                      borderRadius="full"
                      alt={company.name}
                    />
                    <Text color="fg.muted" fontWeight="bold">
                      {company.description}
                    </Text>

                    <Button
                      as={Link}
                      to={`/plans/${company.url}`}
                      variant="primary"
                      _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                    >
                      View Plans
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            ))}

            <Box p={{ base: '4', md: '6' }}>
              <Stack
                spacing={{ base: '4', md: '5' }}
                display="flex"
                height="100%"
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="fg.muted" fontWeight="bold">
                  More coming soon...
                </Text>
              </Stack>
            </Box>
          </SimpleGrid>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={GrooveHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: '2xl' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }}>
          <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Free Plans
            </Text>
            <Divider />
          </HStack>
          <Stack spacing={{ base: '4', md: '5' }} maxW="2xl">
            <Stack spacing="3">
              <Heading size={{ base: 'sm', md: 'md' }}>A great place to start.</Heading>
            </Stack>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              These free plans are a great way to introduce structure to your training and get the
              ball rolling using Sequence.
            </Text>
          </Stack>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                    Boulder Plan <Badge colorScheme="brand">Free</Badge>
                  </Text>
                  <Text textStyle="sm" color="fg.muted" maxW="md">
                    It offers a variety of workouts with the main focus being strength and power.
                    Each week you'll be building on the previous with load and intensity.
                  </Text>
                </Stack>
                <HStack>
                  <Button
                    as={Link}
                    to={'/plans/plan/sequence-boulder-plan-free'}
                    variant="primary"
                    _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                  >
                    View Plan
                  </Button>
                </HStack>
              </Stack>
            </Box>
          </Container>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                    Routes Plan <Badge colorScheme="brand">Free</Badge>
                  </Text>
                  <Text textStyle="sm" color="fg.muted" maxW="md">
                    This plan strikes the balance between strength, power endurance. Each week
                    you'll be building on the previous with load and intensity.
                  </Text>
                </Stack>
                <HStack>
                  <Button
                    as={Link}
                    to={'/plans/plan/sequence-routes-plan-free'}
                    variant="primary"
                    _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                  >
                    View Plan
                  </Button>
                </HStack>
              </Stack>
            </Box>
          </Container>

          <Container py={{ base: '2', md: '4' }} maxW="3xl">
            <Box bg="white" boxShadow="sm" borderRadius="lg" p={{ base: '4', md: '6' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '5', md: '6' }}
                justify="space-between"
              >
                <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                    Combined Plan <Badge colorScheme="brand">Free</Badge>
                  </Text>
                  <Text textStyle="sm" color="fg.muted" maxW="md">
                    A perfect plan for those that like to climb all styles. Each week has a variety
                    of workouts to help progress all aspects of your climbing.
                  </Text>
                </Stack>
                <HStack spacing="4">
                  <Button
                    as={Link}
                    to={'/plans/plan/sequence-combined-plan-free'}
                    variant="primary"
                    _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                  >
                    View Plan
                  </Button>
                </HStack>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={KerrinHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: '2xl' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing={{ base: '12', md: '16' }}>
          <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Custom Plans
            </Text>
            <Divider />
          </HStack>
          <Stack spacing={{ base: '4', md: '5' }} maxW="2xl">
            <Stack spacing="3">
              <Heading size={{ base: 'sm', md: 'md' }}>Tailored for you.</Heading>
            </Stack>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              Everyone is different and the best way to get the most out of yourself is with a
              coach. Tailored Plans connect you with our coaching partners to listen and create a
              plan that delivers results.
            </Text>
            <HStack>
              <Button
                as={ChakraLink}
                variant="primary"
                _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
              >
                Send us an email
              </Button>
            </HStack>
          </Stack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={TrainingHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Box
          bg="white"
          py={{ base: '10', md: '16' }}
          px={{ base: '6', md: '16' }}
          borderRadius="lg"
          boxShadow="sm"
        >
          <Stack
            spacing={{ base: '8', md: '8' }}
            direction={{ base: 'column', lg: 'row' }}
            justify="space-between"
            align="center"
          >
            <Stack spacing="3">
              <Heading size={{ base: 'sm', md: 'md' }}>Sign up for our newsletter</Heading>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Be the first to know when we release new training plans.
              </Text>
            </Stack>
            <form
              action="https://forms.userlist.com/3d71d9df-a6ab-47c8-9b69-1cde7e3983ec/submissions"
              method="POST"
              acceptCharset="UTF-8"
            >
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                width="full"
                maxW={{ base: 'md', xl: 'lg' }}
              >
                <FormControl flex="1">
                  <Input
                    type="email"
                    id="fields_email"
                    name="fields[email]"
                    required
                    placeholder="Enter your email"
                  />
                </FormControl>
                <Button type="submit" variant="primary">
                  Subscribe
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Container>
    </>
  )
}

export default DefaultPlansPage
