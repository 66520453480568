import { Draggable } from 'react-beautiful-dnd'
import { Icon, Box, Spacer, Text } from '@chakra-ui/react'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { Edit3, Move } from 'react-feather'

import securedApi from 'backend/axios'
import { processApiError } from 'helpers/utils'
import Notify from 'components/Notification'

import { useTemplateNote } from '../hooks'

const REST = 'rest'

const DraggableNote = ({ item, idx, refresh }) => {
  const setTemplateNoteOpen = useTemplateNote((state) => state.setTemplateNoteOpen)
  const setTemplateNoteId = useTemplateNote((state) => state.setTemplateNoteId)

  const handleNoteOpen = (id) => {
    setTemplateNoteId(id)
    setTemplateNoteOpen()
  }

  const handleContextMenuDelete = (event, data) => {
    event.stopPropagation()
    if (data.action === 'remove') {
      const { id } = data

      securedApi
        .delete(`api/v1/templated_scheduled_notes/${id}`)
        .then(() => {
          Notify({
            content: 'Note deleted',
            type: 'success',
          })
          refresh()
        })
        .catch((error) => processApiError(error))
    }
  }

  if (item.style === REST) {
    return null
  }
  return (
    <Draggable key={item.id} draggableId={item.id} index={idx}>
      {(provided, snapshot) => {
        const getStyle = (style, snapshot) => {
          if (!snapshot.isDropAnimating) {
            return style
          }
          return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
          }
        }

        return (
          <Box
            key={item.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
            mb="1.5"
            _last={{ marginBottom: 0 }}
          >
            <Box
              bg="white"
              border="1px"
              borderColor="gray.100"
              fontSize="small"
              p="2"
              mb="2"
              background="#fff"
              onClick={() => handleNoteOpen(item.id)}
              cursor="pointer"
              _hover={{ cursor: 'grab' }}
              {...provided.dragHandleProps}
            >
              <ContextMenuTrigger id={item.id}>
                <Box display="flex" flexDirection="row" width="100%" p="1">
                  <Box display="flex" alignItems="center">
                    {item.style === 'note' && (
                      <Icon as={Edit3} strokeWidth="1px" w="4" h="4" mr="1" />
                    )}
                    <Text>{item.title}</Text>
                  </Box>
                  <Spacer />
                  <Icon as={Move} color="gray.400" strokeWidth="1px" w="2" h="2" ml="1" mr="1" />
                </Box>
              </ContextMenuTrigger>
            </Box>

            <Box
              id={item.id}
              as={ContextMenu}
              background="gray.100"
              borderRadius="sm"
              border="1px"
              borderColor="gray.300"
              boxShadow="lg"
              p="2"
            >
              <Box
                as={MenuItem}
                _hover={{ background: 'brand.500', color: 'white' }}
                cursor="pointer"
                px="2"
                py="1"
                borderRadius="sm"
                data={{
                  action: 'remove',
                  id: item.id,
                }}
                onClick={handleContextMenuDelete}
              >
                Delete Note
              </Box>
              <Spacer />
            </Box>
          </Box>
        )
      }}
    </Draggable>
  )
}

export default DraggableNote
