import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import Notify from 'components/Notification'

const schema = z.object({
  name: z.string().min(1, { message: 'Required' }),
})

const AddTemplate = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit = ({ name }) => {
    setLoading(true)
    securedApi
      .post('/api/v1/templated_schedules', { name })
      .then(({ data }) => {
        revalidateLiveQueries()
        Notify({ content: `${name} has been added`, type: 'success' })
        reset()

        const { id } = data
        navigate(`/coaching/template/${id}`)
      })
      .catch((error) => {
        processApiError(error)
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <>
      <Button onClick={onOpen} variant="primary">
        Add Template
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Add Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl marginTop="4" marginBottom="4" isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  {...register('name', {
                    required: true,
                  })}
                  data-1p-ignore
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup spacing="4" marginTop="2" display="flex" width="100%">
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Spacer />
                <Button type="submit" colorScheme="brand" isLoading={loading}>
                  Add
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}

export default AddTemplate
