import { useNavigate } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import Notify from 'components/Notification'
import Dropdown from 'components/General/Dropdown'

const TemplateActivityModalMenu = ({ activityId, templatedActivityId, onRemove }) => {
  const navigate = useNavigate()

  const handleEditWorkout = () => {
    navigate(`/coaching-workout/${templatedActivityId}/edit`)
  }

  const handleDeleteActivity = () => {
    securedApi
      .delete(`api/v1/templated_scheduled_activities/${activityId}`)
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Workout removed', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => onRemove())
  }
  return (
    <>
      <Dropdown>
        <Box onClick={handleEditWorkout} p="2" width="100%">
          <Text fontSize="sm" fontWeight="normal">
            Edit Original Workout
          </Text>
        </Box>
        <Box onClick={handleDeleteActivity} p="2" width="100%">
          <Text fontSize="sm" fontWeight="normal">
            Remove From Template
          </Text>
        </Box>
      </Dropdown>
    </>
  )
}

export default TemplateActivityModalMenu
