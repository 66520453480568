import {
  Box,
  Text,
  Flex,
  Stack,
  Heading,
  Center,
  Image,
  Button,
  HStack,
  Icon,
  List,
  ListItem,
  Link,
} from '@chakra-ui/react'

import { FiCheck } from 'react-icons/fi'

import ActionLink from 'components/General/ActionLink'

import CSLogo from './assets/CSLogo-2.png'

const { REACT_APP_SEQ_ENV } = process.env

const features = [
  'All Climb Strong Training Plans',
  'New Content Added Monthly',
  'Regular Free Updates',
  'Dedicated Customer Support',
  'Limitless Potential',
]

const STRIPE_BUY_CS_URL =
  REACT_APP_SEQ_ENV === 'production'
    ? 'https://buy.stripe.com/5kA02w43f8jS5iM7sz'
    : REACT_APP_SEQ_ENV === 'staging'
    ? 'https://buy.stripe.com/test_9AQ7sx6fydu43eg4go'
    : REACT_APP_SEQ_ENV === 'development' && 'https://buy.stripe.com/test_eVabMv53L1MDgkU005'

const SignUpClimbStrong = () => {
  return (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={{
        md: 'linear(to-r, #333 50%, #F6F6F6 50%)',
      }}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Flex direction="column" px={{ base: '4', md: '8' }} height="full">
            <Flex align="center" mt="10">
              <Image src={CSLogo} maxW="150px" alt="Climb Strong Logo" />
            </Flex>
            <Flex flex="1" align="center">
              <Stack spacing="8">
                <Stack spacing="6">
                  <Heading size={{ md: 'lg', xl: 'xl' }} color="#fff">
                    At Climb Strong, we are dedicated to helping climbers reach higher grades and
                    have more great days on the boulders, at the crag, or in the mountains.
                  </Heading>
                  <Text textStyle="lg" maxW="md" fontWeight="medium" color="#fff">
                    Start your journey today.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
            <Flex align="center" h="24">
              <Text color="white" opacity="0.5" fontSize="sm">
                Powered by <ActionLink to="https://www.sequence-app.com/">Sequence</ActionLink>
              </Text>
            </Flex>
          </Flex>
        </Box>

        <Center flex="1">
          <Box width="full" maxW="md">
            <Flex
              align="center"
              justify="center"
              bg="#333"
              p="4"
              display={{ base: 'flex', md: 'none' }}
            >
              <Image src={CSLogo} objectFit="cover" alt="Climb Strong Logo" />
            </Flex>

            <Box px={{ base: '4', md: '8' }} py={{ base: '12', md: '12' }}>
              <Stack spacing="4" align="center">
                <Stack spacing="4">
                  <Stack spacing="3" align="center">
                    <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                      Climb Strong Membership
                    </Heading>
                  </Stack>
                  <Text
                    color="fg.muted"
                    textStyle={{ base: 'lg', md: 'xl' }}
                    maxW="3xl"
                    textAlign="center"
                  >
                    Get access to ....
                  </Text>
                </Stack>
                <Box
                  bg="white"
                  borderWidth="1px"
                  borderRadius="2xl"
                  boxShadow="sm"
                  px={{ base: '6', md: '8' }}
                  py="8"
                  width="full"
                  maxW="lg"
                >
                  <Stack spacing="8" textAlign="center">
                    <Stack align="center">
                      <Text textStyle={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                        Monthly Subscription
                      </Text>
                      <Heading size={{ base: 'md', md: 'lg' }}>$12</Heading>
                    </Stack>
                    <List spacing="4">
                      {features.map((feature) => (
                        <ListItem key={feature}>
                          <HStack spacing="4">
                            <Icon as={FiCheck} color="brand.500" boxSize={{ base: '4', md: '6' }} />
                            <Text textAlign="left" fontSize={{ base: 'sm', md: 'md' }}>
                              {feature}
                            </Text>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>

                    <Button
                      as={Link}
                      variant="primary"
                      _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                      href={STRIPE_BUY_CS_URL}
                      size="lg"
                      fontWeight="semibold"
                    >
                      Become a Member
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Flex
              align="center"
              justify="flex-end"
              pt="4"
              px={{ base: '4', md: '8' }}
              display={{ base: 'block', md: 'none' }}
            >
              <Text opacity="0.8" fontSize="sm">
                Powered by <ActionLink to="https://www.sequence-app.com/">Sequence</ActionLink>
              </Text>
            </Flex>
          </Box>
        </Center>
      </Flex>
    </Flex>
  )
}

export default SignUpClimbStrong
