import { useState } from 'react'
import {
  Box,
  Text,
  Container,
  Stack,
  Heading,
  Button,
  Image,
  SimpleGrid,
  HStack,
  Divider,
  Link,
  useDisclosure,
} from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import Hero1 from '../assets/climb-strong-hero-1.png'

import PlanDetails from './PlanDetails'

const ClimbStrongMembershipPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedPlan, setSelectedPlan] = useState('')

  const { data, isLoading, hasError } = useQuery('api/v1/training_plans?provider=climbstrong')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const handleViewPlan = (key) => () => {
    setSelectedPlan(key)
    onOpen()
  }

  const handleCloseViewPlan = () => {
    setSelectedPlan('')
    onClose()
  }

  return (
    <>
      <Image
        alt="Training Psych"
        src={Hero1}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />
      <Container pt={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing="3" textAlign="center">
          <Heading size={{ base: 'md', md: 'xl' }}>Climb Strong Training Plans</Heading>
          <Text color="gray.500">
            Choose a plan, train consistently, and let us know how it goes. <br />
            You can always use the “Pay as You Go” coaching option at{' '}
            <Link
              _hover={{ textDecoration: 'none', color: 'brand.600' }}
              href="https://www.climbstrong.com/"
              target="=_blank"
            >
              climbstrong.com
            </Link>{' '}
            to have a chat with a coach.
          </Text>
        </Stack>

        <Stack pt="10" spacing={{ base: '12', md: '16' }}>
          {/* <HStack>
            <Divider />
            <Text textStyle="lg" fontWeight="bold" whiteSpace="nowrap" color="brand.500">
              Plans
            </Text>
            <Divider />
          </HStack> */}

          <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
            {data.map((plan) => (
              <Box
                key={plan.templated_schedule_id}
                bg="white"
                boxShadow="sm"
                borderRadius="lg"
                p={{ base: '4', md: '6' }}
              >
                <Stack spacing={{ base: '4', md: '5' }}>
                  <Stack
                    spacing={{ base: '1', md: '2' }}
                    flex="1"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="bold">{plan.title}</Text>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: plan.description,
                      }}
                    />

                    <Button
                      as={Link}
                      variant="primary"
                      _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                      onClick={handleViewPlan(plan.id)}
                    >
                      View Plan
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            ))}

            <Box p={{ base: '4', md: '6' }}>
              <Stack
                spacing={{ base: '4', md: '5' }}
                display="flex"
                height="100%"
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              ></Stack>
            </Box>
          </SimpleGrid>
          <Text fontWeight="bold" textAlign="center">
            More coming soon
          </Text>
        </Stack>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <Stack spacing="3" align="center">
          <Heading size={{ base: 'sm', md: 'md' }}>Do you have any questions?</Heading>
          <HStack>
            <Link
              isExternal
              fontWeight="bold"
              color="brand.500"
              href="mailto:help@sequence-app.com?subject=Question20Climb%20PStrong%20Training%20Plans"
              _hover={{ textDecoration: 'none', color: 'brand.600' }}
            >
              Send us an email
            </Link>
          </HStack>
        </Stack>
      </Container>

      <PlanDetails isOpen={isOpen} onClose={handleCloseViewPlan} {...{ selectedPlan }} />
    </>
  )
}

export default ClimbStrongMembershipPage
