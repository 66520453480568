import React, { useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { useForm, Controller, FormProvider } from 'react-hook-form'

import { default as QuickModal } from 'components/Modal'

import securedApi from 'backend/axios'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { useQuery } from 'hooks'
import { processApiError, tzSafeDateFormat } from 'helpers/utils'

import {
  InputStyle,
  InputAttempts,
  InputNotes,
} from 'domain/Widgets/Logbook/ActivityComponents/components/Inputs'

export const TableEditModal = ({ id, closeModal, isOpen, onAccept, title, subTitle, minWidth }) => {
  const [processing, setProcessing] = useState(false)
  const [discardEditModalOpen, setDiscardEditModalOpen] = useState(false)

  const methods = useForm()
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = methods

  const { data: entry, isLoading, hasError } = useQuery(`api/v1/widget_logbook_entries/${id}/goal`)

  if (hasError) return null
  if (isLoading) return null

  const { logbook } = entry

  const onCancelEdit = () => {
    if (isDirty) {
      setDiscardEditModalOpen(true)
    } else {
      reset()
      closeModal()
    }
  }

  const handleDiscardEditEntry = () => {
    methods.reset()
    setDiscardEditModalOpen(false)
    closeModal()
  }

  const onSubmit = (entry) => {
    setProcessing(true)
    securedApi
      .put(`api/v1/widget_logbook_entries/control`, {
        id: id,
        widget_logbook_entry: { ...entry },
      })
      .then(({ data }) => {
        revalidateLiveQueries()
        closeModal()
      })
      .catch((error) => {
        processApiError(error)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent minWidth={600}>
        <ModalHeader mr="12">{/*Entry Logbook Entry*/}</ModalHeader>
        <ModalCloseButton m={2} />
        <ModalBody pb="4">
          <Box pt="2">
            <Flex flexDirection="column">
              <Text fontWeight="bold" fontSize={18}>
                {entry.name} ({entry.grade})
              </Text>
              <Text>{tzSafeDateFormat(entry.date, 'd MMM yyyy')}</Text>
            </Flex>
            <FormProvider key={entry.id} {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex gap={12}>
                  <FormControl width="40">
                    <FormLabel marginTop={4} marginBottom={0}>
                      Style
                    </FormLabel>
                    <Controller
                      name="style"
                      defaultValue={entry.style}
                      control={control}
                      render={({ field }) => (
                        <InputStyle {...{ entry, logbook }} marginTop={0} marginLeft={0} />
                      )}
                    />
                  </FormControl>
                  <FormControl width="20">
                    <FormLabel marginTop={4} marginBottom={0}>
                      Attempts
                    </FormLabel>
                    <Controller
                      name="attempts"
                      defaultValue={entry.attempts}
                      control={control}
                      render={({ field }) => (
                        <InputAttempts {...{ entry }} marginTop="0px" marginLeft="0px" />
                      )}
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel marginTop={4} marginBottom={0}>
                    Notes
                  </FormLabel>
                  <Controller
                    name="notes"
                    defaultValue={entry.notes}
                    control={control}
                    render={({ field }) => (
                      <InputNotes {...{ entry, logbook }} marginTop="0px" marginLeft="0px" />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <ButtonGroup spacing="4" marginTop="4" display="flex" width="100%">
                    <Spacer />
                    <Button variant="outline" onClick={onCancelEdit}>
                      Cancel
                    </Button>
                    <Button type="submit" colorScheme="brand" isLoading={processing}>
                      Save
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </form>
            </FormProvider>
          </Box>
        </ModalBody>
      </ModalContent>
      <QuickModal
        isOpen={discardEditModalOpen}
        closeModal={() => setDiscardEditModalOpen(false)}
        title="Are you sure?"
        subTitle="Discard edits. Are you sure?"
        onAccept={() => handleDiscardEditEntry()}
      ></QuickModal>
    </Modal>
  )
}
