import axios from 'axios'

//https://gist.github.com/tuwukee/17ab08d5e69460dcbd4f87e4b145a46f

const { REACT_APP_SEQ_ENV, REACT_APP_CYPRESS } = process.env

const API_URL =
  REACT_APP_SEQ_ENV === 'production'
    ? 'https://dashboard.sequence-app.com'
    : REACT_APP_SEQ_ENV === 'staging'
    ? 'https://sequence-app-staging.herokuapp.com'
    : REACT_APP_SEQ_ENV === 'development'
    ? REACT_APP_CYPRESS === '1'
      ? 'http://localhost:3000'
      : 'http://localhost:3000'
    : ''

const securedApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

const plainApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

securedApi.interceptors.request.use((config) => {
  const method = config.method.toUpperCase()
  if (method !== 'OPTIONS' && method !== 'GET') {
    config.headers = {
      ...config.headers,
      'X-CSRF-TOKEN': localStorage.csrf,
    }
  }
  return config
})

securedApi.interceptors.response.use(null, (error) => {
  if (error.response && error.response.config && error.response.status === 401) {
    return plainApi
      .post('/refresh', {}, { headers: { 'X-CSRF-TOKEN': localStorage.csrf } })
      .then((response) => {
        localStorage.csrf = response.data.csrf
        localStorage.signedIn = true

        let retryConfig = error.response.config
        retryConfig.headers['X-CSRF-TOKEN'] = localStorage.csrf
        return plainApi.request(retryConfig)
      })
      .catch((error) => {
        delete localStorage.csrf
        delete localStorage.signedIn

        // redirect to signin in case refresh request fails
        // if signin page don't redirect so we can surface the errors 🤷

        //this makes sure public pages don't redirect
        // maybe look at a better way

        if (window.pathname.startsWith('/purchase_landing_external/')) {
          return
        }

        if (window.pathname.startsWith('/purchase_landing_external_climb_strong/')) {
          return
        }

        if (window.location.pathname === '/forgot-password') {
          return
        }

        if (window.location.pathname === '/settings/unsubscribe_weekly') {
          return
        }

        if (window.location.pathname === '/settings/unsubscribe_daily') {
          return
        }

        if (window.location.pathname === '/settings/reset-password') {
          return
        }

        if (window.location.pathname === '/sign-up') {
          return
        }

        if (window.location.pathname === '/sign-up-climb-strong') {
          return
        }

        if (window.location.pathname === '/sign-up-9d') {
          return
        }

        if (window.location.pathname === '/sign-up-client') {
          return
        }

        if (window.location.pathname !== '/sign-in') {
          window.location.replace('/sign-in')
        }

        return Promise.reject(error)
      })
  } else {
    return Promise.reject(error)
  }
})

export default securedApi
