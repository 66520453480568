import { Flex, Spinner } from '@chakra-ui/react'

const LoadingSpinner = ({ margin = '4', size = 'md', color = 'gray.500' }) => {
  return (
    <Flex justifyContent="center" m={margin}>
      <Spinner {...{ color, size }} />
    </Flex>
  )
}

export default LoadingSpinner
