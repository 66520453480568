import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Box,
  Card,
  CardHeader,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  Stack,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import HeaderDivider from 'components/General/HeaderDivider'

import { BadgeProject } from './components/BadgeProject'

const GoalsHome = () => {
  const { data, isLoading, hasError } = useQuery('/api/v1/goals')

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const noGoals = !data.length

  const LearnMoreLink = () => (
    <Link
      href="https://docs.sequence-app.com/features/goals"
      isExternal
      fontWeight="bold"
      color="yellow.500"
    >
      Learn more about goals <ExternalLinkIcon />
    </Link>
  )

  const renderCreateButton = () => {
    return (
      <Flex>
        <Button as={NavLink} to="/goals/create" colorScheme="brand" color="white" marginLeft="auto">
          New Goal
        </Button>
      </Flex>
    )
  }

  const renderGoal = ({ id, title, project, status }) => (
    <Link
      key={id}
      as={NavLink}
      to={`/goals/${id}`}
      border="1px"
      borderColor="transparent"
      _hover={{ borderColor: 'brand.500', cursor: 'pointer' }}
    >
      <Card rounded="0">
        <CardHeader>
          <Heading size="sm">
            <Flex justifyContent="space-between">
              <Box>{title}</Box>
              {project &&
                (status == 'complete' ? (
                  <Box title="Project completed">
                    <Text>✓</Text>
                  </Box>
                ) : (
                  <Box>
                    <BadgeProject />
                  </Box>
                ))}
            </Flex>
          </Heading>
        </CardHeader>
      </Card>
    </Link>
  )

  const renderGoals = () => {
    if (noGoals) {
      return (
        <Text textAlign="center" mt="4">
          You currently haven't setup any goals. <LearnMoreLink />
        </Text>
      )
    }

    const inProgressGoals = data.filter((goal) => goal.status === 'in_progress')
    const ideaGoals = data.filter((goal) => goal.status === 'idea')
    const completedGoals = data.filter((goal) => goal.status === 'complete')
    const cancelledGoals = data.filter((goal) => goal.status === 'cancelled')
    const onHoldGoals = data.filter((goal) => goal.status === 'on_hold')

    return (
      <Stack spacing="10">
        {inProgressGoals.length && (
          <Box>
            <HeaderDivider heading="In Progress" />
            <Stack spacing="4">{inProgressGoals.map((goal) => renderGoal(goal))}</Stack>
          </Box>
        )}

        {ideaGoals.length && (
          <Box>
            <HeaderDivider heading="Ideas" />
            <Stack spacing="4">{ideaGoals.map((goal) => renderGoal(goal))}</Stack>
          </Box>
        )}

        {completedGoals.length && (
          <Box>
            <HeaderDivider heading="Completed" />
            <Stack spacing="4">{completedGoals.map((goal) => renderGoal(goal))}</Stack>
          </Box>
        )}

        {cancelledGoals.length && (
          <Box>
            <HeaderDivider heading="Cancelled" />
            <Stack spacing="4">{cancelledGoals.map((goal) => renderGoal(goal))}</Stack>
          </Box>
        )}

        {onHoldGoals.length && (
          <Box>
            <HeaderDivider heading="On Hold" />
            <Stack spacing="4">{onHoldGoals.map((goal) => renderGoal(goal))}</Stack>
          </Box>
        )}

        {!noGoals && (
          <Flex justifyContent="flex-end">
            <LearnMoreLink />
          </Flex>
        )}
      </Stack>
    )
  }

  return (
    <Container maxW="container.lg" py="6" mb="24">
      <Box display="block" margin="0 auto" width="100%" maxW="600px">
        {renderCreateButton()}
        {renderGoals()}
      </Box>
    </Container>
  )
}

export default GoalsHome
