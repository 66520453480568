import React from 'react'
import { Tr } from '@chakra-ui/react'
import { Draggable } from 'react-beautiful-dnd'

import { ContentRow, CreateEditRow } from './'
import { tableWidths } from 'domain/Widgets/Logbook/constants'

const LogbookRows = ({
  editId,
  handleAddAttempt,
  handleCancelEdit,
  handleCreateProjectGoalFromEntry,
  handleDeleteLogbookEntry,
  handleDiscardNewEntry,
  handleDiscardEditEntry,
  handleEditEntry,
  handleSubmitLogbookEntry,
  isEditing,
  isNameFocused,
  isNotesFocused,
  logbook,
  logbooks,
  onSetLogbooks,
  scheduledActivityId,
  showEditButton,
  highlightLogbookEntryId,
  onSubmit,
}) => {
  return (
    <>
      {logbook.entries.map((entry, idx) => {
        const key = entry.id
        const isHighlighted = highlightLogbookEntryId === entry.id

        return (
          <Draggable key={key} draggableId={key} index={idx}>
            {(provided, snapshot) => {
              const { isDragging } = snapshot
              const backgroundColor = isHighlighted ? '#fedc74' : isDragging ? '#f8f8f8' : 'white'

              return (
                <Tr
                  ref={provided.innerRef}
                  height={tableWidths.row}
                  backgroundColor={backgroundColor}
                  {...provided.draggableProps}
                >
                  {entry.id === editId ? (
                    <CreateEditRow
                      key={'CreateEditRow'}
                      provided={provided}
                      snapshot={snapshot}
                      {...{
                        entry,
                        handleCancelEdit,
                        handleDeleteLogbookEntry,
                        handleDiscardEditEntry,
                        handleDiscardNewEntry,
                        handleSubmitLogbookEntry,
                        isEditing,
                        isNameFocused,
                        isNotesFocused,
                        logbook,
                        logbooks,
                        onSetLogbooks,
                        onSubmit,
                        scheduledActivityId,
                      }}
                    />
                  ) : (
                    <ContentRow
                      key={key}
                      provided={provided}
                      snapshot={snapshot}
                      {...{
                        entry,
                        handleAddAttempt,
                        handleCreateProjectGoalFromEntry,
                        handleEditEntry,
                        idx,
                        logbook,
                        showEditButton,
                      }}
                    />
                  )}
                </Tr>
              )
            }}
          </Draggable>
        )
      })}
    </>
  )
}

export default LogbookRows
