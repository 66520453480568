import React from 'react'
import { Select } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { pickStyleMapping } from 'domain/Widgets/Logbook/constants'
import { InputRequired } from './'
import { InputStyleFlags } from './'

const renderStyleOptions = (logbook) => {
  const styleMapping = pickStyleMapping(logbook)

  return Object.entries(styleMapping).map((x) => (
    <option key={x[0]} value={x[0]}>
      {x[1]}
    </option>
  ))
}

const InputStyle = ({ entry, logbook, onFieldChange, ...props }) => {
  const { register } = useFormContext()

  return (
    <>
      <Select
        key="InputStyle"
        size="sm"
        marginTop="2px"
        marginLeft="1px"
        borderColor="gray.400"
        variant="outline"
        placeholder=" "
        {...register('style', {
          required: 'Required',
          value: entry.style,
        })}
        {...props}
      >
        {renderStyleOptions(logbook)}
      </Select>
      <InputRequired field="style" />
      <InputStyleFlags {...{ entry, logbook }} />
    </>
  )
}

export default InputStyle
