import { Text, useDisclosure } from '@chakra-ui/react'

import TemplateApplyModal from './TemplateApplyModal'

const AddTemplateToClientAction = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text p="2" fontSize="sm" onClick={onOpen}>
        Add to client
      </Text>
      {isOpen && <TemplateApplyModal templateId={id} {...{ isOpen, onClose }} />}
    </>
  )
}

export default AddTemplateToClientAction
