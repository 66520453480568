import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Text,
} from '@chakra-ui/react'

import LoadingSpinner from 'components/LoadingSpinner'

import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'

const ClientName = ({ id, isOpen, onClose, displayName }) => {
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, watch, reset } = useForm({ defaultValues: { displayName } })

  const handleSubmitConfirmation = () => {
    handleSubmit(onSubmit)()
    onClose()
  }

  const onSubmit = ({ displayName }) => {
    setLoading(true)
    securedApi
      .put(`api/v1/coaching/connections/${id}`, {
        display_name: displayName,
      })
      .then(() => {
        Notify({ content: 'Name updated', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error updating name. Please try again.')
      })
      .finally(() => {
        onClose()
        reset()
        revalidateLiveQueries()
        setLoading(false)
      })
  }

  const currentDisplayName = watch('displayName')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(handleSubmitConfirmation)}>
        <ModalContent>
          <ModalHeader>Client Name</ModalHeader>

          {loading ? (
            <ModalBody pb="4">
              <LoadingSpinner />
            </ModalBody>
          ) : (
            <>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <Input {...register('displayName')} />
                </FormControl>
                <Box pt="4">
                  <Text fontSize="sm" color="gray.500">
                    Note: set this field to blank to use the client's own name
                  </Text>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="brand"
                  type="submit"
                  isDisabled={currentDisplayName === displayName}
                >
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  )
}

export default ClientName
