import React, { useState } from 'react'
import { Box, Button, Input, Flex, Text, Tooltip, VStack, useDisclosure } from '@chakra-ui/react'

import { useDemoData, useMetricsDays } from 'hooks'

import Modal from 'components/Modal'

export const LastXDays = ({ isChangeable = true }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const days = useMetricsDays((state) => state.days)
  const setDays = useMetricsDays((state) => state.setDays)

  const demoData = useDemoData((state) => state.demoData)

  const [internalValue, setInternalValue] = useState(days)

  const setBoth = (value) => {
    setInternalValue(value)
    setDays(value)
    onClose()
  }

  const handleChangeState = (event) => {
    setInternalValue(event.target.value)
  }

  const handleSetDays = () => {
    setDays(internalValue)
    onClose()
  }

  const QuickButton = ({ value }) => {
    const text = value === 'all' ? 'All Time' : value
    return <Button onClick={() => setBoth(value)}>{text}</Button>
  }

  if (demoData) {
    return (
      <Tooltip label="You can change the visible period with real data">
        <Text
          fontSize="x-small"
          textTransform="uppercase"
          color="gray.500"
          display="inline"
          cursor="pointer"
          onClick={onOpen}
        >
          Last{' '}
          <Text as="span" borderBottomColor="brand.600" borderBottomWidth="1px">
            {days}
          </Text>{' '}
          Days
        </Text>
      </Tooltip>
    )
  }

  if (!isChangeable) {
    return (
      <>
        <Text fontSize="x-small" textTransform="uppercase" color="gray.500" display="inline">
          Last <Text as="span">{days}</Text> Days
        </Text>
      </>
    )
  } else {
    return (
      <>
        <Text
          fontSize="x-small"
          textTransform="uppercase"
          color="gray.500"
          display="inline"
          cursor="pointer"
          onClick={onOpen}
        >
          {internalValue === 'all' ? (
            <Text as="span" borderBottomColor="brand.600" borderBottomWidth="1px">
              All time
            </Text>
          ) : (
            <Text>
              Last{' '}
              <Text as="span" borderBottomColor="brand.600" borderBottomWidth="1px">
                {days}
              </Text>{' '}
              Days
            </Text>
          )}
        </Text>

        {isOpen && (
          <Modal isOpen={isOpen} closeModal={onClose} title="Set time period">
            <VStack>
              <Box mb="4">
                <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
                  Presets
                </Text>
                <Flex alignItems="center" gap={2} mt="1">
                  {[7, 30, 90, 365, 'all'].map((value) => {
                    return <QuickButton key={`lastXDays-${value}`} value={value} />
                  })}
                </Flex>
                <Box mt="4">
                  <Box>
                    <Text fontSize="x-small" textTransform="uppercase" color="gray.500">
                      Custom
                    </Text>
                    <Flex alignItems="center" justifyContent="center" gap={2}>
                      <Text>Last</Text>
                      <Input
                        width={65}
                        textAlign="center"
                        value={internalValue === 'all' ? '' : internalValue}
                        onChange={handleChangeState}
                      />
                      <Text>days</Text>
                    </Flex>
                  </Box>
                  <Button colorScheme="brand" size="sm" width="100%" mt="2" onClick={handleSetDays}>
                    Set
                  </Button>
                </Box>
              </Box>
            </VStack>
          </Modal>
        )}
      </>
    )
  }
}

export default LastXDays
