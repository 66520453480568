export const statusMapColour = {
  in_progress: 'brand',
  idea: 'purple',
  complete: 'green',
  cancelled: 'red',
  on_hold: 'red',
}

export const statusMapText = {
  in_progress: 'In progress',
  idea: 'Idea',
  complete: 'Complete',
  cancelled: 'Cancelled',
  on_hold: 'On hold',
}

export const styleMapText = {
  undefined: 'N/A',
  short_term: 'Short term',
  medium_term: 'Medium term',
  long_term: 'Long term',
  project: 'Project',
}

export const renderGoalDurationOptions = () => (
  <>
    <option value="undefined">{styleMapText['undefined']}</option>
    <option value="short_term">{styleMapText['short_term']}</option>
    <option value="medium_term">{styleMapText['medium_term']}</option>
    <option value="long_term">{styleMapText['long_term']}</option>
  </>
)

export const renderGoalStatusOptions = () => (
  <>
    <option value="idea">{statusMapText['idea']}</option>
    <option value="in_progress">{statusMapText['in_progress']}</option>
    <option value="complete">{statusMapText['complete']}</option>
    <option value="cancelled">{statusMapText['cancelled']}</option>
    <option value="on_hold">{statusMapText['on_hold']}</option>
  </>
)
