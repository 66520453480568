import { Icon, useDisclosure } from '@chakra-ui/react'
import { Coffee } from 'react-feather'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import Modal from 'components/Modal'
import { processApiError } from 'helpers/utils'
import { revalidateLiveQueries } from 'helpers/swrConfig'

const RestDayAction = ({ notes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDeleteRestDay = () => {
    const restDayData = notes?.find((item) => item.style === 'rest')

    securedApi
      .delete(`api/v1/templated_scheduled_notes/${restDayData.id}`)
      .then(() => {
        revalidateLiveQueries()
        Notify({
          content: 'Rest day removed',
          type: 'success',
        })
      })
      .catch((error) => processApiError(error))
  }

  return (
    <>
      <Icon
        as={Coffee}
        color="gray.800"
        strokeWidth="1px"
        w="3"
        h="3"
        ml="2"
        mt="1"
        _hover={{ cursor: 'pointer' }}
        onClick={onOpen}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          closeModal={onClose}
          title="Remove rest day?"
          onAccept={handleDeleteRestDay}
        />
      )}
    </>
  )
}

export default RestDayAction
