import React, { useState, useMemo } from 'react'
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  useDisclosure,
} from '@chakra-ui/react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { XCircle } from 'react-feather'

import TextOverflow from 'components/TextOverflow'
import ActivityModal from 'domain/Planner/components/ActivityModal'
import { tzSafeDateFormat } from 'helpers/utils'

import { pickStyleMapping } from 'domain/Widgets/Logbook/constants'

import { Filter, Pagination } from './'

const EntriesTable = ({ data, logbook, showPagination = true }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [scheduledActivityId, setScheduledActivityId] = useState(null)
  const [highlightLogbookEntryId, setHighlightLogbookEntryId] = useState(null)

  const styleMapping = pickStyleMapping(logbook)
  const filterOptionsStyle = Object.entries(styleMapping).map((x) => ({ value: x[0], text: x[1] }))
  const filterOptionsGrade = logbook.filter_options_grade

  const handleDateClick = ({ scheduledActivityId, logbookEntryId }) => {
    setScheduledActivityId(scheduledActivityId)
    setHighlightLogbookEntryId(logbookEntryId)
    onOpen()
  }

  const hoverProps = { cursor: 'pointer', color: 'brand.500' }

  // max-width: var(--chakra-sizes-5xl) = 64rem = 1024px for 16pt
  const columnWidths = {
    date: 60,
    name: 200,
    grade: 75, // constraint: yds values
    style: 85, // constraint: redpoint
    attempts: 30,
    notes: 280,
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        maxSize: columnWidths['date'],
        enableColumnFilter: false,
        cell: (info) => {
          const value = info.getValue()
          const formattedDate = tzSafeDateFormat(value, 'd MMM yyyy')

          return (
            <Flex justifyContent="flex-end">
              <Text
                onClick={() => {
                  handleDateClick({
                    scheduledActivityId: info.row.original.scheduled_activity_id,
                    logbookEntryId: info.row.original.id,
                  })
                }}
                _hover={hoverProps}
                style={{ textAlign: 'right' }}
              >
                {formattedDate}
              </Text>
            </Flex>
          )
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        maxSize: columnWidths['name'],
        cell: (info) => {
          const value = info.getValue()

          return (
            <Box>
              <TextOverflow
                onClick={() => {
                  info.column.setFilterValue(value)
                  info.column.getFilterValue()
                }}
                _hover={hoverProps}
              >
                {info.getValue()}
              </TextOverflow>
            </Box>
          )
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'grade',
        header: 'Grade',
        maxSize: columnWidths['grade'],
        meta: { options: filterOptionsGrade },
        sortingFn: 'gradeSortingFn',
        cell: (info) => (
          <Box>
            <Text>{info.getValue()}</Text>
          </Box>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'style',
        header: 'Style',
        maxSize: columnWidths['style'],
        meta: { options: filterOptionsStyle },
        cell: (info) => (
          <Box>
            <Text>{styleMapping[info.getValue()]}</Text>
          </Box>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'attempts',
        header: 'Attempts',
        maxSize: columnWidths['attempts'],
        cell: (info) => (
          <Box>
            <Text>{info.getValue()}</Text>
          </Box>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        maxSize: columnWidths['notes'],
        cell: (info) => (
          <Box>
            <TextOverflow>{info.getValue()}</TextOverflow>
          </Box>
        ),
        footer: (props) => props.column.id,
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    sortingFns: {
      gradeSortingFn: (rowA, rowB, columnId) => {
        const columnIdRank = 'grade_rank'
        return rowA.original[columnIdRank] > rowB.original[columnIdRank]
          ? 1
          : rowA.original[columnIdRank] < rowB.original[columnIdRank]
          ? -1
          : 0
      },
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    // no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      pagination,
    },
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering
  })

  const RemoveFilterButton = (props) => {
    return (
      <Box as="button" mt="-5px" {...props}>
        <Icon
          as={XCircle}
          w="5"
          h="5"
          color="red.400"
          _hover={{ cursor: 'pointer', color: 'gray.500' }}
        />
      </Box>
    )
  }

  const sortIconAsc = '↑'
  const sortIconDesc = '↓'

  return (
    <>
      <TableContainer height="650px" bg="#fff" p="4" overflowY="auto">
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{ width: header.column.getSize(), maxWidth: header.column.getSize() }}
                    verticalAlign="top"
                  >
                    <Flex flexDirection="column">
                      <Flex justifyContent="space-between">
                        <Text
                          mb={2} // to accomodate button padding + whitespace
                          {...{
                            cursor: header.column.getCanSort() ? 'pointer' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' ' + sortIconAsc,
                            desc: ' ' + sortIconDesc,
                          }[header.column.getIsSorted()] ?? null}
                        </Text>
                        <Flex>
                          {header.column.getFilterValue() && (
                            <RemoveFilterButton
                              onClick={() => header.column.setFilterValue(undefined)}
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      {header.column.getCanFilter() && (
                        <Filter column={header.column} table={table} styleMapping={styleMapping} />
                      )}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        style={{ width: cell.column.getSize(), maxWidth: cell.column.getSize() }}
                        fontSize="sm"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {showPagination && <Pagination table={table} />}
      {isOpen && (
        <ActivityModal
          id={scheduledActivityId}
          isOpen={isOpen}
          onClose={onClose}
          highlightLogbookEntryId={highlightLogbookEntryId}
        />
      )}
    </>
  )
}

export default EntriesTable
