import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { Container, Button, ButtonGroup, Spacer } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { processGetError, processApiError, categoryOptionFormatter } from 'helpers/utils'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import { FormHeading } from 'components/Forms'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import {
  NameSection,
  CategorySection,
  DurationSection,
  DescriptionSection,
  WidgetSection,
  OnRockSection,
  DetailsSection,
  OwnerNotesSection,
} from 'domain/Workouts/FormComponents'

import ActionsMenu from './Components/ActionsMenu'

const CoachingWorkoutEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const methods = useForm()
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    setLoading(true)

    securedApi
      .get('api/v1/templated_categories')
      .then((response) => {
        setOptions(categoryOptionFormatter(response.data))
      })
      .catch((error) => processGetError(error))

    securedApi
      .get(`api/v1/templated_activities/${id}`)
      .then(({ data }) => {
        let defaultValues = {}
        defaultValues.name = data.activity_name
        defaultValues.category = {
          label: data.category_name,
          value: data.category_id,
        }
        defaultValues.description = data.activity_description
        defaultValues.details = data.activity_details
        defaultValues.outdoor = data.outdoor || false
        defaultValues.owner_notes = data.owner_notes

        const adjustedDuration = Math.floor(Number(data.duration) / 60 / 1000)
        defaultValues.duration = adjustedDuration ? adjustedDuration : 0

        reset({ ...defaultValues })

        setWidgets(data.templated_widgets)
      })
      .catch((error) => processGetError(error))
      .finally(() => setLoading(false))
  }, [id, reset])

  const onSubmit = (data) => {
    const { name, description, category, details, duration, outdoor, owner_notes } = data

    const adjustedDuration = Math.floor(Number(duration) * 60 * 1000)

    setLoading(true)

    securedApi
      .patch(`api/v1/templated_activities/${id}`, {
        templated_activity: {
          name,
          description,
          templated_category_id: category.value,
          details,
          duration: adjustedDuration,
          outdoor,
          owner_notes,
        },
      })
      .then(() => {
        navigate('/coaching/workouts')
        Notify({ content: 'Workout updated.', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => setLoading(false))
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Container maxW="container.lg" mb="28">
      <InnerPageWrapperActions>
        <ActionsMenu isEditable={false} {...{ id }} />
      </InnerPageWrapperActions>

      <Container maxW="container.sm">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHeading heading="Edit Coaching Workout" />
            <NameSection {...{ register, errors }} />
            <DescriptionSection {...{ register, errors }} />
            <CategorySection {...{ control, options, errors }} />
            <DurationSection {...{ register }} />
            <OnRockSection {...{ register }} />

            <WidgetSection
              activityId={id}
              createWidgets={widgets}
              setCreateWidgets={setWidgets}
              templatedActivity
            />

            <DetailsSection {...{ control }} />
            <OwnerNotesSection {...{ register }} />

            <ButtonGroup spacing="4" display="flex" alignItems="center">
              <Spacer />
              <Button variant="outline" onClick={() => navigate(`/coaching-workout/${id}`)}>
                Cancel
              </Button>

              <Button type="submit" isLoading={loading} loadingText="Updating" colorScheme="brand">
                Update
              </Button>
            </ButtonGroup>
          </form>
        </FormProvider>
      </Container>
    </Container>
  )
}

export default CoachingWorkoutEdit
