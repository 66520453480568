import React from 'react'
import { Box, Text } from '@chakra-ui/react'

import { tzSafeDateFormat } from 'helpers/utils'

export const GoalDates = ({ startDate, endDate }) => {
  const dateFormat = 'd MMM yyyy'

  return (
    <Box>
      {startDate && endDate ? (
        <Text fontSize="xs" color="gray.500" as="span">
          {tzSafeDateFormat(startDate, dateFormat)}
          {' — '}
          {tzSafeDateFormat(endDate, dateFormat)}
        </Text>
      ) : startDate ? (
        <Text fontSize="xs" color="gray.500" as="span">
          Started: {tzSafeDateFormat(startDate, dateFormat)}
        </Text>
      ) : endDate ? (
        <Text fontSize="xs" color="gray.500" as="span">
          Complete by: {tzSafeDateFormat(endDate, dateFormat)}
        </Text>
      ) : null}
    </Box>
  )
}
