import { NavLink } from 'react-router-dom'
import { Box, Stack, StackDivider, Button, Flex, Text, Link } from '@chakra-ui/react'

import { useImpersonate, useCoaching, useIsMobile } from 'hooks'

import { useSignOut, useDisplayUpgrade } from 'domain/Navigation/hooks'

import UpgradeButton from 'components/General/UpgradeButton'

import { NavItem } from 'domain/Navigation/Components/Links'
import { MainNavigation, SecondaryNavigation } from 'domain/Navigation/Components/DesktopNavigation'
import {
  MainMobileNavigation,
  SecondaryMobileNavigation,
} from 'domain/Navigation/Components/MobileNavigation'
import Logo from 'domain/Navigation/Components/Logo'
import CoachingModeToggle from 'domain/Navigation/CoachingModeToggle'
import AdminBanner from 'domain/Navigation/AdminBanner'
import { getLogoURL } from 'domain/Navigation/helpers'

const Navigation = () => {
  const { isMobile } = useIsMobile()
  const { signOut } = useSignOut()
  const impersonateData = useImpersonate((state) => state.impersonateData)
  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)
  const { displayUpgrade } = useDisplayUpgrade()

  if (isMobile) {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px="6"
        py="2"
        borderBottom="1px"
        borderColor="gray.300"
        position="fixed"
        top="0"
        background="gray.50"
        width="100%"
        zIndex="20"
      >
        <MainMobileNavigation />
        <Box alignItems="center">
          <Link as={NavLink} to={getLogoURL(isCoachingInterfaceEnabled)}>
            <Logo />
          </Link>
        </Box>
        <SecondaryMobileNavigation />
      </Flex>
    )
  }

  return (
    <Flex height="100vh" background="gray.50">
      <Stack
        flex="1"
        w="220px"
        py="8"
        px="6"
        borderRightWidth="1px"
        justifyContent="space-between"
        overflow="auto"
      >
        <Stack spacing="8">
          <Stack spacing="3">
            <Link as={NavLink} to={getLogoURL(isCoachingInterfaceEnabled)} mb="2">
              <Flex alignItems="center">
                <Logo />
                <Text
                  fontSize="sm"
                  pt="1"
                  pl="2"
                  color="gray.700"
                  textTransform="uppercase"
                  fontWeight="semibold"
                >
                  Sequence
                </Text>
              </Flex>
            </Link>

            {impersonateData.impersonateAsAdmin && <AdminBanner {...{ impersonateData }} />}

            {!isCoachingInterfaceEnabled ? (
              <>
                <MainNavigation />
                <SecondaryNavigation />
              </>
            ) : (
              <>
                <NavItem to="/coaching" title="Dashboard" icon="dashboard" />
                <NavItem to="/coaching/clients" title="Clients" icon="clients" />
                <NavItem to="/coaching/workouts" title="Coaching Workouts" icon="workouts" />
                <NavItem to="/coaching/templates" title="Templates" icon="templates" />
              </>
            )}
          </Stack>
        </Stack>

        <Stack spacing="4" divider={<StackDivider />}>
          {displayUpgrade ? <UpgradeButton /> : <Box />}

          <Stack spacing="3" alignItems="flex-start">
            <CoachingModeToggle />
            {isCoachingInterfaceEnabled && (
              <NavItem to="/coaching/billing" title="Billing" fontSize="sm" />
            )}
            <NavItem to="/settings" title="Settings" fontSize="sm" />
            <Button
              onClick={signOut}
              color="gray.500"
              variant="link"
              fontSize="sm"
              _hover={{ textDecoration: 'none', color: 'brand.500' }}
            >
              Sign Out
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default Navigation
