import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

const DaysBreakdownTooltip = (props) => {
  if (!props.active) {
    return null
  }

  // Seems to sometimes return null which causes things to bomb.
  if (!props.payload?.[0]) {
    return null
  }

  let label = 'n/a'
  if (props.payload?.[0]) {
    label = props.payload[0].payload.date_text
    const start_date = props.payload[0].payload.week_start_date
    const end_date = props.payload[0].payload.week_end_date
    label = `${start_date} to ${end_date}`
  }

  const finalStyle = {
    margin: 0,
    padding: 10,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    whiteSpace: 'nowrap',
    ...props.contentStyle,
  }

  return (
    <Box style={finalStyle}>
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 'smaller',
          marginBottom: '8px',
        }}
      >
        {label}
      </Text>
      {props.payload.map((p, index) => {
        const zeroValue = p.value === '0' || p.value === 0

        return (
          <Flex gap={2} mb={1} key={index}>
            <Box p={3} bg={p.color}></Box>
            <Text fontWeight={zeroValue && 'bold'}>{p.value}</Text>
            <Text>{p.name}</Text>
          </Flex>
        )
      })}
    </Box>
  )
}

export default DaysBreakdownTooltip
