import React from 'react'
import { YAxis, Line, Legend, Tooltip, Text } from 'recharts'

import { useDemoData, useMetricsDays, useQuery } from 'hooks'

import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import DaysBreakdownTooltip from 'domain/Metrics/components/DaysBreakdownTooltip'

import { MetricsChart } from './CommonMeasure'

const DaysBreakdown = () => {
  const demoData = useDemoData((state) => state.demoData)
  const days = useMetricsDays((state) => state.days)

  const { data, isLoading, hasError } = useQuery(
    `api/v1/metrics/days_breakdown?demo=${demoData}&days=${days}`
  )

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { weekly, annotations } = data.days_breakdown

  return (
    <MetricsChart
      item={{ data: weekly }}
      annotations={annotations}
      yMin={'zero'}
      yMax={7.5}
      tooltip={
        <Tooltip
          allowEscapeViewBox={{ x: true }}
          position={{ y: 30 }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{
            opacity: 0.8,
            position: 'relative',
            left: 20,
          }}
          content={<DaysBreakdownTooltip />}
        />
      }
    >
      <Legend
        wrapperStyle={{
          paddingTop: '15px',
        }}
      />
      <YAxis
        yAxisId="left"
        orientation="left"
        domain={[0, 8]}
        ticks={[0, 1, 2, 3, 4, 5, 6, 7]}
        label={
          <Text x={0} y={0} dx={50} dy={200} offset={0} angle={-90}>
            Days
          </Text>
        }
      />
      <Line
        yAxisId="left"
        connectNulls
        name="Days on rock"
        dataKey="days_on_rock"
        stroke="#fdb619"
        type="monotone"
        activeDot={{ r: 8 }}
      />
      <Line
        yAxisId="left"
        connectNulls
        name="Rest days"
        dataKey="rest_days"
        stroke="#82ca9d"
        type="monotone"
        activeDot={{ r: 8 }}
      />
    </MetricsChart>
  )
}
export default DaysBreakdown
