import React, { useState } from 'react'
import { Box, Td } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import Modal from 'components/Modal'

import { CancelEditButton, DeleteButton, DiscardButton, LogbookButtons, SaveButton } from './'
import { InputName, InputGrade, InputStyle, InputAttempts, InputNotes } from './Inputs'

import { checkAllFieldsEmpty } from 'helpers/utils'
import { tableWidths } from 'domain/Widgets/Logbook/constants'

// Still issues:
//
// only for editing existing entry
// - when typing, change of isDirty state causes refocus on notes
// - save IconButton size (make others buttons?)
// - maybe now hook functions will work being called lower down
//   (rather than having to pass them all the way through)

const CreateEditRow = ({
  provided, // won't be present when adding a new entry
  snapshot,
  entry,
  handleCancelEdit,
  handleDeleteLogbookEntry,
  handleDiscardEditEntry,
  handleDiscardNewEntry,
  handleSubmitLogbookEntry,
  isEditing,
  isNameFocused,
  isNotesFocused,
  logbook,
  logbooks,
  onSetLogbooks,
  onSubmit,
  scheduledActivityId,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [discardNewModalOpen, setDiscardNewModalOpen] = useState(false)
  const [discardEditModalOpen, setDiscardEditModalOpen] = useState(false)

  const { getValues, handleSubmit } = useFormContext()

  // not working
  // this triggers a rerender when typing the first character (i.e. when isDirty changes)
  // which causes the current input to lose focus
  //
  // const { isDirty } = useFormState()

  const isDirty = true

  const handleDiscardNewEntryButtonClick = () => {
    const values = getValues()
    const allFieldsEmpty = checkAllFieldsEmpty(values)

    if (allFieldsEmpty) {
      handleDiscardNewEntry()
    } else {
      setDiscardNewModalOpen(true)
    }
  }

  const handleDiscardEditEntryButtonClick = () => {
    if (isDirty) {
      setDiscardEditModalOpen(true)
    } else {
      handleDiscardEditEntry()
    }
  }

  const TdForm = ({ field, children, ...props }) => {
    return (
      <Td
        style={{
          paddingLeft: field === 'name' ? 10 : 2,
          paddingRight: 2,
          paddingTop: 10,
          paddingBottom: 10,
          // backgroundColor: '#F7FAFC',
        }}
        verticalAlign="top"
        // needs to be minWidth to prevent header row shifting
        minWidth={tableWidths[field]}
        {...props}
      >
        {children}
      </Td>
    )
  }

  return (
    <>
      <TdForm field="name">
        <InputName {...{ entry, logbook, isNameFocused }} />
      </TdForm>
      <TdForm field="grade_id">
        <InputGrade {...{ entry, logbook }} />
      </TdForm>
      <TdForm field="style">
        <InputStyle {...{ entry, logbook }} />
      </TdForm>
      <TdForm field="attempts">
        <InputAttempts {...{ entry }} />
      </TdForm>
      <TdForm field="notes">
        <InputNotes {...{ entry, logbook, isNotesFocused, onSubmit }} />
      </TdForm>
      <TdForm field="buttons" maxWidth={tableWidths.buttons_edit} padding={0}>
        <LogbookButtons flexDirection="column" alignItems="center" marginTop={2}>
          <SaveButton onClick={handleSubmit((values) => onSubmit(logbook, values))} />
          {isEditing ? (
            <>
              <CancelEditButton onClick={() => handleDiscardEditEntryButtonClick()} />
              <DeleteButton onClick={() => setDeleteModalOpen(true)} />
            </>
          ) : (
            <>
              <DiscardButton onClick={() => handleDiscardNewEntryButtonClick()} />
            </>
          )}
        </LogbookButtons>

        {/* this row also still needs to have dragHandleProps so we add a hidden one */}
        {isEditing && (
          <Box style={{ display: 'none' }} {...(provided && provided.dragHandleProps)}>
            x
          </Box>
        )}

        {/* these modals can't be direct children of a <tr> element so put them in the <td> */}
        <Modal
          isOpen={deleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          title="Are you sure?"
          subTitle="Delete entry. Are you sure?"
          onAccept={() => handleDeleteLogbookEntry({ entry, logbooks, logbook, onSetLogbooks })}
        ></Modal>
        <Modal
          isOpen={discardNewModalOpen}
          closeModal={() => setDiscardNewModalOpen(false)}
          title="Are you sure?"
          subTitle="Discard new entry. Are you sure?"
          onAccept={() => handleDiscardNewEntry()}
        ></Modal>
        <Modal
          isOpen={discardEditModalOpen}
          closeModal={() => setDiscardEditModalOpen(false)}
          title="Are you sure?"
          subTitle="Discard edits. Are you sure?"
          onAccept={() => handleDiscardEditEntry()}
        ></Modal>
      </TdForm>
    </>
  )
}

export default CreateEditRow
