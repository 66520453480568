import { Text, Box, useDisclosure } from '@chakra-ui/react'

import AddWeekLabel from './AddWeekLabel'

const RowLabel = ({ refresh, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const weekLabel = data?.label?.content
  const defaultWeekLabelContent = data?.label?.content
  const defaultWeekLabelId = data?.label?.id
  const week = data?.week

  return (
    <>
      {weekLabel ? (
        <Box maxWidth="400px" min="20px">
          <Text
            onClick={onOpen}
            noOfLines={1}
            fontWeight="bold"
            fontSize="small"
            _hover={{ cursor: 'pointer' }}
          >
            {weekLabel}
          </Text>
        </Box>
      ) : (
        <Box h="20px" />
      )}
      <AddWeekLabel
        {...{
          week,
          defaultWeekLabelContent,
          defaultWeekLabelId,
          isOpen,
          onClose,
          refresh,
        }}
      />
    </>
  )
}

export default RowLabel
