export const tableWidths = {
  // widths
  // table: '33rem', // if modal is 36rem, i.e. size="xl"
  // table: '39rem', // if modal is 42rem, i.e. size="2xl")
  table: '45rem', // if modal is 48rem, i.e. size="3xl")

  name: '11rem',
  grade_id: '5.3rem', // min 5.3rem because of YDS
  style: '6.7rem', // min 6.7rem because of Redpoint
  attempts: '3.9rem',
  notes: '15.1rem',

  // must be 4rem if edit has 3 buttons in a row, can be 3 or 2.5 if flexWrap used
  // 3rem is nice as it adds a buffer
  buttons: '3rem',

  name_input: '171px',

  // heights
  row: '2.5rem',
}

export const pickStyleMapping = (logbook) => {
  if (logbook.name === 'Boulder') {
    return styleMappingBoulder
  } else {
    return styleMappingRoute
  }
}

export const styleMappingRoute = {
  onsight: 'Onsight',
  flash: 'Flash',
  redpoint: 'Redpoint',
  attempt: 'Attempt',
  work: 'Work',
  repeat: 'Repeat',
}

export const styleMappingBoulder = {
  onsight: 'Onsight',
  flash: 'Flash',
  redpoint: 'Sent',
  attempt: 'Attempt',
  work: 'Work',
  repeat: 'Repeat',
}

export const styleColourMapping = {
  onsight: '#82ca9d',
  flash: '#fdb619',
  redpoint: '#d84848',
  attempt: '#556677',
  work: '#96b1fd',
  repeat: '#ea9c9a',
}

export const hideAttemptsStyles = ['onsight', 'flash']

export const LOGBOOK_ENTRY_FORM_OPEN_TEXT = 'A logbook entry is currently being edited'
