import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Stack, Text, Link } from '@chakra-ui/react'
import { format } from 'date-fns'

import securedApi from 'backend/axios'
import { processAppError, tzSafeDateFormat } from 'helpers/utils'
import { useQuery, useStateManager } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import UpgradeButton from 'components/General/UpgradeButton'
import Modal from 'components/Modal'
import Notify from 'components/Notification'
import Error from 'components/General/Error'
import { TRIAL_PERIOD_SCHEDULED_LIMIT } from 'helpers/constants'

const Account = () => {
  let navigate = useNavigate()

  const { clearSubscribed } = useStateManager()

  const { data, isLoading, isValidating, hasError } = useQuery('/accounts/status')
  const [modalOpen, setModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  const redirectToPortal = () => {
    securedApi
      .get(`/payments/subscriptions/portal`)
      .then((response) => {
        const win = window.open(response.data.url, '_blank')
        win.focus()
      })
      .catch(() => {
        Notify({
          content: 'There was an error, please try allowing pop-up windows!',
          type: 'error',
        })
      })
  }

  const cancelSubscription = () => {
    setIsCancelling(true)

    securedApi
      .post(`/payments/subscriptions/delete`)
      .then(() => {
        clearSubscribed()
        navigate('/subscription-cancelled')
      })
      .catch((error) => {
        processAppError(error)
      })
      .finally(() => {
        setIsCancelling(false)
      })
  }

  const handleCancelSubscription = () => {
    cancelSubscription()
    setModalOpen(false)
  }

  const ExtraText = ({ text }) => {
    if (text) {
      return (
        <Text marginTop="2" fontSize="sm">
          {text}
        </Text>
      )
    } else {
      return null
    }
  }

  const mapPlatformText = (platform) => {
    // eslint-disable-next-line default-case
    switch (platform) {
      case 'web':
        return 'this web version'
      case 'ios':
        return 'iOS'
      case 'android':
        return 'Android'
    }
  }

  const ManageSubscription = ({ platform, hideCancelLink }) => {
    if (platform === 'web') {
      return (
        <Stack spacing="3" marginTop={4}>
          <Link color="brand.500" onClick={() => redirectToPortal()}>
            Change plan
          </Link>

          <Link color="brand.500" onClick={() => redirectToPortal()}>
            Update card
          </Link>

          <Link color="brand.500" onClick={() => redirectToPortal()}>
            View billing history
          </Link>

          {!hideCancelLink && (
            <Link color="red" onClick={() => setModalOpen(true)}>
              Cancel your subscription
            </Link>
          )}

          <Modal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            onAccept={() => handleCancelSubscription()}
            title="Cancel Subscription"
            subTitle="Are you sure?"
          />
        </Stack>
      )
    } else {
      const platformText = mapPlatformText(platform)

      return (
        <Box marginTop={4}>
          <Text>
            Please manage your subscription on the platform that you purchased it (for you that is{' '}
            {platformText}).
          </Text>
        </Box>
      )
    }
  }

  if (hasError) return <Error />

  if (isLoading || isValidating || isCancelling) {
    return <LoadingSpinner />
  }

  const {
    platform,
    plan,
    valid_trial,
    expired_trial,
    valid_subscription,
    bonus_subscription_as_coaching_client,
    amnesty_period_after_ceasing_as_coaching_client,
    bonus_subscription_with_plan_purchase,
    subscribed_with_membership_provider,
    subscribed_while_on_plan_purchase,
    subscription_cancelled,
    subscription_overdue,
    subscription_expired,
    no_subscription_or_trial,
    next_payment_date,
    trial_start_date,
    trial_end_date,
    extra_description,
  } = data

  const formattedPaymentDate = next_payment_date
    ? tzSafeDateFormat(next_payment_date, 'd MMM yyyy')
    : null

  const trialStartDate = trial_start_date ? tzSafeDateFormat(trial_start_date, 'd MMM yyyy') : null
  const trialEndDate = trial_end_date ? tzSafeDateFormat(trial_end_date, 'd MMM yyyy') : null

  const hideManageSubscriptionPlans = ['Sequence (team)', 'Sequence (life)', 'Sequence (free)']
  const hideManageSubscription = hideManageSubscriptionPlans.includes(plan)
  const showManageSubscription = !hideManageSubscription

  const handleExportDataLink = () => {
    securedApi
      .get(`accounts/export`, {
        responseType: 'blob',
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)
        const timestamp = format(new Date(), 'yyyyMMdd-hhmmss') // okay to use new Date() here
        const filename = `sequence_export_${timestamp}.zip`

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
  }

  const TextInvalid = ({ children, ...props }) => {
    return (
      <Text marginTop="4" style={{ color: 'rgb(220 38 38)' }} {...props}>
        {children}
      </Text>
    )
  }

  return (
    <Stack spacing="10">
      <Box>
        <Text fontWeight="bold">Account</Text>
        <Text>Plan: {plan}</Text>

        {valid_trial && (
          <>
            {trialEndDate ? (
              <Text>Trial end date: {trialEndDate}</Text>
            ) : (
              trialStartDate && <Text>Trial start date: {trialStartDate}</Text>
            )}
            <Text>
              Your trial allows you to have {TRIAL_PERIOD_SCHEDULED_LIMIT} workouts scheduled in
              your planner.
            </Text>
            <Box marginTop="4">
              <UpgradeButton />
            </Box>
          </>
        )}

        {expired_trial && (
          <>
            {trialEndDate ? (
              <Text>Trial end date: {trialEndDate}</Text>
            ) : (
              trialStartDate && <Text>Trial start date: {trialStartDate}</Text>
            )}
            <Box marginTop="4">
              <UpgradeButton />
            </Box>
          </>
        )}

        {valid_subscription &&
          (showManageSubscription ? (
            <>
              <Text size="base">Next payment date: {formattedPaymentDate}</Text>
              <ManageSubscription platform={platform} />
            </>
          ) : (
            <ExtraText text={extra_description} />
          ))}

        {bonus_subscription_as_coaching_client && (
          <Flex flexDirection="column" mt={4} gap={2}>
            <Box>
              <Text>
                You have a full subscription to Sequence as part of being coached by{' '}
                {data.coach_name}.
              </Text>
            </Box>
            <Box>
              <Text>
                If you cease to be coached, then you will be transitioned to a standard Sequence
                subscription.
              </Text>
              <Text>Don't worry — all your data will still be saved in Sequence regardless.</Text>
            </Box>
            <ExtraText text={extra_description} />
          </Flex>
        )}

        {amnesty_period_after_ceasing_as_coaching_client && (
          <Flex flexDirection="column" mt={4} gap={2}>
            <Box>
              <Text>You were previously on a subscription as a result of being a coached.</Text>
            </Box>
            <Box>
              <Text>
                Since you are no longer being coached, you will now need a valid subscription to
                continue using Sequence. We've given you a free month in the meantime until to make
                a decision.
              </Text>
            </Box>
            <Text>This free period will end on {formattedPaymentDate}</Text>
            <Text>
              After this date you will need a subscription to add and complete new workouts.
            </Text>

            <Box marginTop="4">
              <UpgradeButton text="Subscribe" counter={false} />
            </Box>
          </Flex>
        )}

        {bonus_subscription_with_plan_purchase && (
          <Flex flexDirection="column" mt={4} gap={2}>
            <Box>
              <Text>
                You received a bonus 3 month subscription to Sequence from purchasing a training
                plan.
              </Text>
              <Text>This will end on {formattedPaymentDate}</Text>
            </Box>
            <Box>
              <Text>
                Purchase an ongoing subscription now to ensure no interruptions to your account.
              </Text>
              <Text>
                Don't worry — you won't be charged until after your bonus subscription ends!
              </Text>
            </Box>
            <Box>
              <UpgradeButton text="Subscribe" counter={false} />
            </Box>
            <ExtraText text={extra_description} />
          </Flex>
        )}

        {subscribed_with_membership_provider &&
          (showManageSubscription ? (
            <>
              <Box mt={2}>
                <Text>
                  You have a full subscription to Sequence as part of your Climb Strong membership
                </Text>
                <Text size="base">Next payment date: {formattedPaymentDate}</Text>
                <ManageSubscription platform={platform} />
              </Box>
            </>
          ) : (
            <ExtraText text={extra_description} />
          ))}

        {subscribed_while_on_plan_purchase &&
          (showManageSubscription ? (
            <>
              <Box mt={2}>
                <Text>
                  You received a bonus 3 month subscription to Sequence from purchasing a training
                  plan.
                </Text>
                <Text>
                  This will end on {formattedPaymentDate} and then your Sequence subscription will
                  start
                </Text>
              </Box>
              <ManageSubscription platform={platform} hideCancelLink={true} />
            </>
          ) : (
            <ExtraText text={extra_description} />
          ))}

        {subscription_overdue &&
          (showManageSubscription ? (
            <>
              <Box bg="#eee" marginTop={2} padding={4}>
                <TextInvalid marginTop={0}>
                  Your subscription is overdue. Please check your payment details.
                </TextInvalid>
                <TextInvalid>
                  We will retry charging a few times. If all attempts fail, your account will be
                  considered expired and you'll need to resubscribe.
                </TextInvalid>
                <TextInvalid>Last payment due date: {formattedPaymentDate}</TextInvalid>
              </Box>
              <ManageSubscription platform={platform} />
            </>
          ) : (
            <ExtraText text={extra_description} />
          ))}

        {subscription_cancelled && (
          <>
            <TextInvalid>Subscription cancelled</TextInvalid>
            <Box marginTop="4">
              <UpgradeButton text="Restart subscription" counter={false} />
            </Box>
          </>
        )}

        {subscription_expired && (
          <>
            <TextInvalid>Subscription expired</TextInvalid>
            <Box marginTop="4">
              <UpgradeButton text="Restart subscription" counter={false} />
              {platform !== 'web' && (
                <Text marginTop={4}>
                  Your original platform was {mapPlatformText(platform)} but you can start a new
                  subscription here if you prefer.
                </Text>
              )}
            </Box>
          </>
        )}

        {no_subscription_or_trial && (
          <>
            <Text>Something seems wrong</Text>
            <Text>Please contact us and we will sort it out.</Text>
          </>
        )}
      </Box>

      <Box>
        <Text fontWeight="bold">Export Data</Text>
        <Text>Retrieve an archive of all of your data</Text>
        <Link color="brand.500" onClick={handleExportDataLink}>
          Download
        </Link>
      </Box>
    </Stack>
  )
}

export default Account
