import React, { useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import RichTextArea from 'components/RichTextArea'

import { processApiError } from 'helpers/utils'

const CreateNote = ({ templateId, open, onClose, style, day, onSuccess }) => {
  const [processing, setProcessing] = useState(false)

  const methods = useForm()
  const { control, register, handleSubmit } = methods

  const onSubmit = ({ title, details }) => {
    setProcessing(true)

    securedApi
      .post(`api/v1/templated_scheduled_notes`, {
        templated_scheduled_note: {
          templated_schedule_id: templateId,
          title,
          style,
          week: day.week,
          day: day.day,
          pos: 1,
          description: details,
        },
      })
      .then(({ data }) => {
        Notify({ content: 'Created', type: 'success' })
        onSuccess(data)
      })
      .catch((error) => {
        processApiError(error)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  let headingTitle = 'Note'

  return (
    <Modal isOpen={open} onClose={onClose} size="2xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader pb="0">{headingTitle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="4">
                <FormControl isRequired>
                  <FormLabel fontSize="sm" mb="0">
                    Title
                  </FormLabel>
                  <Input autoFocus placeholder="Awesome training day" {...register('title')} />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="sm" mb="0">
                    Details
                  </FormLabel>
                  <Controller
                    name="details"
                    control={control}
                    render={({ field }) => <RichTextArea {...field} />}
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup spacing="4" marginTop="4" display="flex" justifyContent="flex-end">
                <Button variant="outline" onClick={() => onClose()} disabled={processing}>
                  Cancel
                </Button>
                <Button type="submit" colorScheme="brand" isLoading={processing}>
                  Create
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}

export default CreateNote
