import { NavLink } from 'react-router-dom'
import { Link, MenuItem } from '@chakra-ui/react'

import NavigationIcons from 'domain/Navigation/Components/NavigationIcons'

export const NavItem = ({ to, title, fontSize = 'base', icon }) => {
  return (
    <Link
      as={NavLink}
      display="flex"
      alignItems="center"
      color="gray.500"
      _activeLink={{ color: 'black' }}
      _hover={{ textDecoration: 'none', color: 'brand.500' }}
      end
      {...{ fontSize, to }}
    >
      {icon && <NavigationIcons icon={icon} />}

      {title}
    </Link>
  )
}

export const MenuLink = ({ to, title }) => {
  return (
    <MenuItem as={NavLink} to={to} _activeLink={{ color: 'black' }} p="2">
      {title}
    </MenuItem>
  )
}
