import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Spacer,
  Grid,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { Edit3, XCircle, PlusCircle } from 'react-feather'

import Select from 'react-select'

import { useQuery } from 'hooks'
import securedApi from 'backend/axios'
import { groupNonArchivedWorkouts, processApiError } from 'helpers/utils'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'
import SimpleModal from 'components/Modal'

import { useTemplateBulkAddWorkout } from 'domain/Coaching/Templates/hooks'
import AddWeekLabel from 'domain/Coaching/Templates/Components/AddWeekLabel'
import CreateNote from 'domain/Coaching/Templates/Components/CreateNote'

import { customStyles } from './helpers'

const DayActions = ({ templateId, day, refresh, isOpen, onClose, onAddWorkout }) => {
  const {
    isOpen: isDestructiveModalOpen,
    onOpen: onDestructiveModalOpen,
    onClose: onCloseDestructiveModal,
  } = useDisclosure()

  const {
    isOpen: isWeekLabelOpen,
    onOpen: onWeekLabelOpen,
    onClose: onCloseWeekLabelOpen,
  } = useDisclosure()

  const [isAddingLoading, setIsAddingLoading] = useState(false)
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [noteStyle, setNoteStyle] = useState()
  const [destructiveAction, setDestructiveAction] = useState()

  const [isMenuOpen, setMenuOpen] = useState(false)

  const setBulkAddWorkoutOpen = useTemplateBulkAddWorkout((state) => state.setBulkAddWorkoutOpen)
  const setBulkAddWorkoutDate = useTemplateBulkAddWorkout((state) => state.setBulkAddWorkoutDate)

  const { data, isLoading, hasError } = useQuery('api/v1/templated_activities/add_list')

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (hasError) {
    return <Error />
  }

  // TODO: this could use a streamlined endpoint
  // const {
  //   data: dayData,
  //   isLoading: isDayDataLoading,
  //   hasError: hasDayDataError,
  // } = useQuery(`api/v1/schedules/user_day?date=${formattedDate}`)

  // if (hasError || hasDayDataError) {
  //   return <Error />
  // }

  const handleSearchItemSelected = (event) => {
    setIsAddingLoading(true)
    onAddWorkout({
      id: event.id,
    })
  }

  const handleRecentItemSelected = (id) => {
    setIsAddingLoading(true)
    onAddWorkout({
      id,
    })
  }

  const handleOpenBulkAddWorkout = () => {
    setBulkAddWorkoutDate(day)
    setBulkAddWorkoutOpen()
    onClose()
  }

  const displayNoteCreation = ({ style }) => {
    setCreateNoteOpen(true)
    setNoteStyle(style)
  }

  const handleNoteCreation = () => {
    refresh()
    setCreateNoteOpen(false)
    onClose()
  }

  const handleRestDay = () => {
    securedApi
      .post(`api/v1/templated_scheduled_notes`, {
        templated_scheduled_note: {
          templated_schedule_id: templateId,
          title: 'Rest day',
          style: 'rest',
          week: day.week,
          day: day.day,
        },
      })
      .then(() => {
        Notify({ content: 'Marked as rest day', type: 'success' })
        refresh()
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleDeleteRestDay = () => {
    const restDayData = day.notes.find((item) => item.style === 'rest')
    securedApi
      .delete(`api/v1/templated_scheduled_notes/${restDayData.id}`)
      .then(() => {
        refresh()
        Notify({
          content: 'Rest day removed',
          type: 'success',
        })
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleClearWeek = () => {
    securedApi
      .put(`api/v1/templated_schedules/${templateId}/clear_week?week=${day.week}`)
      .then(() => {
        refresh()
        Notify({ content: 'Week cleared', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => {
        onCloseDestructiveModal()
        onClose()
      })
  }

  const handleDeleteWeek = () => {
    securedApi
      .put(`api/v1/templated_schedules/${templateId}/delete_week?week=${day.week}`)
      .then(() => {
        refresh()
        Notify({ content: 'Week deleted', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => {
        onCloseDestructiveModal()
        onClose()
      })
  }

  const handleCopyWeek = () => {
    securedApi
      .put(`api/v1/templated_schedules/${templateId}/duplicate_week?week=${day.week}`)
      .then(() => {
        refresh()
        Notify({ content: 'Week duplicated', type: 'success' })
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleInsertWeek = () => {
    securedApi
      .put(`api/v1/templated_schedules/${templateId}/insert_week?week=${day.week}`)
      .then(() => {
        refresh()
        Notify({ content: 'Week inserted', type: 'success' })
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  // React Select component traps the esc key when focused. This helps close the modal with the esc key
  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && !isMenuOpen) {
      e.stopPropagation()
      onClose()
    }
  }

  const handleCloseWeekLabel = () => {
    onCloseWeekLabelOpen()
    onClose()
  }

  const renderContent = () => {
    const currentDay = day.title
    const currentWeek = day.week
    const restDay = day?.notes?.find((i) => i.style === 'rest')
    const defaultWeekLabelContent = day.label?.content
    const defaultWeekLabelId = day.label?.id

    const { activities, recent } = data

    const workoutOptions = groupNonArchivedWorkouts(activities)
    const recentWorkouts = recent

    return (
      <>
        <ModalHeader>
          <Text fontSize="sm">
            {currentDay} - Week {currentWeek}
          </Text>
        </ModalHeader>

        <ModalBody mb="4">
          <Box display="flex" alignItems="center" mb="2">
            <Box>
              <Text fontWeight="bold">Add Workout</Text>
            </Box>
            <Spacer />
            <Box>
              <Button size="xs" onClick={() => handleOpenBulkAddWorkout()}>
                Bulk Add
              </Button>
            </Box>
          </Box>
          {isAddingLoading ? (
            <LoadingSpinner margin="2" />
          ) : (
            <Select
              autoFocus
              onKeyDown={handleKeyDown}
              onMenuClose={handleMenuClose}
              onMenuOpen={handleMenuOpen}
              styles={customStyles}
              options={workoutOptions}
              placeholder="Search workouts..."
              closeMenuOnSelect={true}
              onChange={handleSearchItemSelected}
              noOptionsMessage={() => 'No workouts found'}
            />
          )}

          <Box mt="4">
            {recentWorkouts?.length > 0 && (
              <>
                <Box mb="2">
                  <Text fontSize="sm" fontWeight="semibold">
                    Recent Workouts
                  </Text>
                </Box>
                <HStack wrap="wrap" spacing="2" mb="4">
                  {recentWorkouts.map(({ id, category_hex_colour, name }) => (
                    <Button
                      key={id}
                      size="xs"
                      borderLeft="10px"
                      borderStyle="solid"
                      borderColor={category_hex_colour}
                      onClick={() => handleRecentItemSelected(id)}
                    >
                      {name}
                    </Button>
                  ))}
                </HStack>
              </>
            )}

            <Box>
              <Text fontSize="sm" fontWeight="semibold">
                Extra Actions
              </Text>
            </Box>

            <Grid
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
              gap={4}
              mt="2"
            >
              <Button
                leftIcon={<Edit3 strokeWidth="1px" size="16px" />}
                size="xs"
                onClick={() => displayNoteCreation({ style: 'note' })}
              >
                Add note
              </Button>
              <Button
                leftIcon={
                  restDay ? (
                    <XCircle strokeWidth="1px" size="16px" />
                  ) : (
                    <PlusCircle strokeWidth="1px" size="16px" />
                  )
                }
                size="xs"
                onClick={() => (restDay ? handleDeleteRestDay() : handleRestDay())}
              >
                Rest day
              </Button>
            </Grid>
          </Box>

          {day.day === 1 && (
            <Box mt="4">
              <Box>
                <Text fontSize="sm" fontWeight="semibold">
                  Week Actions
                </Text>
              </Box>
              <Grid
                templateColumns={{
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                gap={4}
                mt="2"
              >
                <Button size="xs" onClick={onWeekLabelOpen}>
                  {defaultWeekLabelId ? 'Update week label ' : 'Add a week label'}
                </Button>
                <Button
                  size="xs"
                  onClick={() => {
                    setDestructiveAction('clear')
                    onDestructiveModalOpen()
                  }}
                >
                  Clear week
                </Button>
                <Button
                  size="xs"
                  onClick={() => {
                    setDestructiveAction('delete')
                    onDestructiveModalOpen()
                  }}
                >
                  Delete week
                </Button>
                <Button size="xs" onClick={handleCopyWeek}>
                  Duplicate week
                </Button>
                <Button size="xs" onClick={handleInsertWeek}>
                  Insert week
                </Button>
              </Grid>
            </Box>
          )}
        </ModalBody>

        {createNoteOpen && (
          <CreateNote
            open={createNoteOpen}
            style={noteStyle}
            onClose={() => setCreateNoteOpen(false)}
            onSuccess={handleNoteCreation}
            {...{ templateId, day }}
          />
        )}

        <AddWeekLabel
          week={currentWeek}
          isOpen={isWeekLabelOpen}
          onClose={handleCloseWeekLabel}
          {...{
            defaultWeekLabelContent,
            defaultWeekLabelId,
            refresh,
            templateId,
          }}
        />

        <SimpleModal
          isOpen={isDestructiveModalOpen}
          closeModal={onCloseDestructiveModal}
          onAccept={() => {
            destructiveAction === 'clear' ? handleClearWeek() : handleDeleteWeek()
          }}
          title="Are you sure?"
          subTitle="This action can't be undone"
        />
      </>
    )
  }
  // const isDataLoading = isLoading || isDayDataLoading

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      {/* <ModalContent>{isDataLoading ? <LoadingSpinner /> : renderContent()}</ModalContent> */}
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  )
}

export default DayActions
