import React, { useEffect, useState } from 'react'
import { Input, ListItem, UnorderedList } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import securedApi from 'backend/axios'

import { KEY_ENTER, KEY_ARROW_UP, KEY_ARROW_DOWN } from 'helpers/keyboard'

import { tableWidths } from 'domain/Widgets/Logbook/constants'

const InputName = ({ entry, logbook, isNameFocused }) => {
  const [nameSuggestions, setNameSuggestions] = useState([])
  const [state, setState] = useState({
    activeSuggestionIndex: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    localValue: entry.name || '',
  })

  const { register, setValue, setFocus } = useFormContext()

  const { activeSuggestionIndex, filteredSuggestions, showSuggestions, localValue } = state

  // not working
  // this is triggered whenever the form rerenders
  // i.e when style is change to show/hide attempts, this setFocus gets called again
  //
  // // focus on the name field if desired
  // useEffect(() => {
  //   if (isNameFocused) {
  //     setFocus('name')
  //   }
  // }, [isNameFocused])

  useEffect(() => {
    fetchNameSuggestions(logbook)
  }, [logbook])

  // fetch name/grade values for suggestion dropdown
  const fetchNameSuggestions = (logbook) => {
    securedApi
      .get(`api/v1/widget_logbooks/${logbook.id}/name_suggestions`)
      .then(({ data }) => {
        setNameSuggestions(data)
      })
      .catch((error) => {
        // no need to do anything when catching error (but still need to catch it)
        // if it fails then no suggestions will be displayed
      })
  }

  const chooseSuggestion = (suggestion) => {
    setState({
      activeSuggestionIndex: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      localValue: suggestion.name,
    })

    setValue('name', suggestion.name)
    setValue('grade_id', suggestion.grade_id)
  }

  // Callbacks:
  // the internal state is updated every change (onChange) in order to manage the suggestion list
  // the suggested ListItem selection should be onMouseDown instead of onClick to prevent a rerender
  // (this may not be an issue without an onBlur callback, but definitely is with one)

  const onChange = (e) => {
    const localValue = e.currentTarget.value
    const filteredSuggestions = nameSuggestions.filter(
      (suggestion) => suggestion.name.toLowerCase().indexOf(localValue.toLowerCase()) > -1
    )

    setState({
      activeSuggestionIndex: 0,
      filteredSuggestions,
      showSuggestions: true,
      localValue,
    })
  }

  const onKeyDown = (e) => {
    const { activeSuggestionIndex, filteredSuggestions } = state

    if (e.keyCode === KEY_ENTER) {
      e.preventDefault() // stop form submitting
      const selectedSuggestion = filteredSuggestions[activeSuggestionIndex]
      chooseSuggestion(selectedSuggestion)
      setFocus('style')
    } else if (e.keyCode === KEY_ARROW_UP) {
      if (activeSuggestionIndex === 0) {
        return
      }
      setState((prevState) => ({
        ...prevState,
        activeSuggestionIndex: prevState.activeSuggestionIndex - 1,
      }))
    } else if (e.keyCode === KEY_ARROW_DOWN) {
      if (activeSuggestionIndex >= filteredSuggestions.length - 1) {
        return
      }
      setState((prevState) => ({
        ...prevState,
        activeSuggestionIndex: prevState.activeSuggestionIndex + 1,
      }))
    }
  }

  const onClickSuggestion = (suggestion) => {
    chooseSuggestion(suggestion)
  }

  let suggestionsListComponent
  if (showSuggestions && localValue) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <UnorderedList
          style={{
            position: 'absolute',
            border: '1px solid #999',
            borderTopWidth: '0',
            backgroundColor: '#fafafa',
            listStyle: 'none',
            marginTop: '0px',
            maxHeight: '145px',
            overflowY: 'scroll',
            paddingLeft: '0',
            marginLeft: '1px',
            width: '307px',
            zIndex: 999,
          }}
        >
          {filteredSuggestions.map((suggestion, index) => {
            const isSelectedItem = index === activeSuggestionIndex
            const selectedColour = '#ccc'

            return (
              <ListItem
                key={`${suggestion.name}-${suggestion.grade_id}`}
                style={{
                  padding: '0.5rem',
                  paddingLeft: '12px',
                  cursor: 'pointer',
                  backgroundColor: isSelectedItem && selectedColour,
                }}
                onMouseDown={() => onClickSuggestion(suggestion)}
              >
                {suggestion.name} ({suggestion.grade})
              </ListItem>
            )
          })}
        </UnorderedList>
      )
    } else {
      suggestionsListComponent = <></>
    }
  }

  return (
    <React.Fragment>
      <input type="hidden" {...register('id', { value: entry.id })} />
      <Input
        size="sm"
        maxWidth={tableWidths['name_input']}
        marginTop="2px"
        marginLeft="1px"
        onKeyDown={onKeyDown}
        {...register('name', {
          value: localValue,
          onChange: onChange,
        })}
        autoComplete="off" // prevent browser suggestions
        {...{ 'data-1p-ignore': true }} // prevent 1Password icon
      />
      {suggestionsListComponent}
    </React.Fragment>
  )
}

export default InputName
