import { useNavigate } from 'react-router-dom'
import { Button, Box, Center, Heading, HStack, Icon, Image, Stack, Flex } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

import videoWebM from './assets/climb-strong-introduction.webm'
import videoMp4 from './assets/climb-strong-introduction.mp4'
import posterImage from './assets/climb-strong-poster.png'

import CSLogo from 'domain/Auth/assets/CSLogo-2.png'

const WelcomeClimbStrong = () => {
  const navigate = useNavigate()

  return (
    <Box height="100vh" bg="#333" py="12" overflow="auto">
      <Box maxWidth="840px" width="100%" px="4" margin="auto">
        <Center>
          <Image src={CSLogo} maxW="150px" alt="Climb Strong Logo" />
        </Center>

        <Stack alignItems="center" spacing="8" mt="10">
          <Box>
            <Heading
              textAlign="center"
              size="xl"
              color="white"
              fontWeight="thin"
              textTransform="uppercase"
              letterSpacing="widest"
            >
              Welcome to Climb Strong Training!
            </Heading>
            <Heading
              textAlign="center"
              size="sm"
              color="white"
              fontWeight="thin"
              textTransform="uppercase"
              letterSpacing="widest"
            >
              powered by Sequence
            </Heading>
          </Box>
        </Stack>

        <Flex justifyContent="center" _hover={{ cursor: 'pointer' }} my="8">
          <video
            controls
            disablePictureInPicture={true}
            controlsList="nodownload noplaybackrate"
            poster={posterImage}
            style={{ objectFit: 'fill', maxWidth: '720px' }}
          >
            <source src={videoWebM} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
          </video>
        </Flex>

        <HStack spacing="3" justifyContent="flex-end">
          <Button
            background="brand.500"
            letterSpacing="wide"
            color="white"
            _hover={{ background: 'brand.600' }}
            onClick={() => navigate('/plans?firstTime=true')}
          >
            Let's get started <Icon fontWeight="semibold" boxSize={4} ml="2" as={FaArrowRight} />
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}
export default WelcomeClimbStrong
