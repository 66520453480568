import React from 'react'
import { FormControl, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import securedApi from 'backend/axios'
import { processApiError, processApiErrorCode } from 'helpers/utils'
import { revalidateLiveQueries } from 'helpers/swrConfig'

import Notify from 'components/Notification'

const TemplateName = ({ id, name }) => {
  const methods = useForm({ defaultValues: { name: name } })
  const { register } = methods

  const handleOnBlur = (e) => {
    const newValue = e.target.value

    if (newValue !== name) {
      securedApi
        .patch(`api/v1/templated_schedules/${id}`, {
          templated_schedule: {
            name: newValue,
          },
        })
        .then(() => {
          Notify({ content: 'Template name updated', type: 'success' })
          revalidateLiveQueries()
        })
        .catch((error) => {
          if (processApiErrorCode(error, 'NAME_TAKEN')) {
            Notify({ content: 'Template name already taken', type: 'error' })
          } else {
            processApiError(error)
          }
        })
    }
  }

  return (
    <FormControl marginBottom="0">
      <Input
        type="text"
        {...register('name', {
          onBlur: (e) => handleOnBlur(e),
        })}
        data-1p-ignore
      />
    </FormControl>
  )
}

export default TemplateName
