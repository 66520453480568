import React from 'react'
import { Box, Button, Stack, Tooltip } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { LOGBOOK_ENTRY_FORM_OPEN_TEXT } from 'domain/Widgets/Logbook/constants'
import { processApiError } from 'helpers/utils'
import { useActivityModal } from 'hooks'

import CompleteEmoji from './CompleteEmoji'

const CompleteActionButton = ({ children, ...props }) => {
  const preventActivityModalClose = useActivityModal((state) => state.preventActivityModalClose)

  return (
    <Tooltip label={preventActivityModalClose && LOGBOOK_ENTRY_FORM_OPEN_TEXT}>
      <Button
        // disable if logbook entry open
        isDisabled={preventActivityModalClose}
        {...props}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

const CompleteActions = (props) => {
  const {
    completed,
    incomplete,
    currentActivityId,
    outdoor,
    measures,
    sliders,
    notes,
    duration,
    onSetCompleted,
    onSetIncomplete,
    onCloseModal,
    refresh,
  } = props

  const handleComplete = ({ update }) => {
    // if user hasn't interacted with fields we just need to make sure the values are set

    // TODO to helper and shared between web/mobile
    // sequence-web/frontend/src/domain/Schedule/ActivityComponents/CompleteActions.js
    // sequence-mobile/views/schedule/ScheduledWorkout.js
    const adjustedMeasures = measures.map((item) => {
      // if values have been cleared, but they have been updated before
      // is this needed? what if user wants to clear them?
      if (item.value === null && item.updated) {
        item.value = item.default_value
      }
      if (item.value_2 === null && item.updated_2) {
        item.value_2 = item.default_value_2
      }
      if (item.value_3 === null && item.updated_3) {
        item.value_3 = item.default_value_3
      }

      // assign default value if values have not been set manually in the form or updated previously
      if (item.value === null && item.default_value !== null && !item.updated) {
        item.value = item.default_value
      }
      if (item.value_2 === null && item.default_value_2 !== null && !item.updated_2) {
        item.value_2 = item.default_value_2
      }
      if (item.value_3 === null && item.default_value_3 !== null && !item.updated_3) {
        item.value_3 = item.default_value_3
      }

      return item
    })

    // might need this to handle nulls
    const adjustedSliders = sliders

    securedApi
      .patch(`api/v1/scheduled_activities/${currentActivityId}`, {
        scheduled_activity: {
          completed: true,
          incomplete: false,
          notes,
          widgets: { measure: adjustedMeasures, slider: adjustedSliders },
          actual_duration: duration,
          actual_outdoor: outdoor,
        },
      })
      .then(() => {
        //used for refreshing the dashboard
        if (refresh) {
          refresh()
        }

        if (update) {
          Notify({
            content: 'Workout updated',
            type: 'success',
          })
        } else {
          const NotifyContent = () => (
            <p>
              Workout completed. <br />
              Good job <CompleteEmoji />
            </p>
          )
          Notify({
            content: <NotifyContent />,
            type: 'success',
          })
        }

        onSetCompleted(true)
        onSetIncomplete(false)
        onCloseModal()
      })
      .catch((error) => processApiError(error))
  }

  const handleReschedule = () => {
    securedApi
      .put(`api/v1/scheduled_activities/${currentActivityId}/reschedule`, {})
      .then(() => {
        Notify({
          content: 'Workout updated.',
          type: 'success',
        })

        onSetCompleted(false)
        onCloseModal()
      })
      .catch((error) => processApiError(error))
  }

  return (
    <Box marginY="5">
      {completed ? (
        <Stack direction="row" spacing={4}>
          <CompleteActionButton width="100%" onClick={() => handleReschedule()}>
            Reschedule
          </CompleteActionButton>
          <CompleteActionButton
            width="100%"
            colorScheme="yellow"
            color="white"
            onClick={() => handleComplete({ update: true })}
          >
            Update
          </CompleteActionButton>
        </Stack>
      ) : incomplete ? (
        <CompleteActionButton width="100%" colorScheme="yellow" color="white" isDisabled>
          Complete Workout
        </CompleteActionButton>
      ) : (
        <CompleteActionButton
          width="100%"
          colorScheme="yellow"
          color="white"
          onClick={() => handleComplete({ update: false })}
        >
          Complete Workout
        </CompleteActionButton>
      )}
    </Box>
  )
}

export default CompleteActions
