import { useParams, useNavigate } from 'react-router-dom'
import { Box, Link, Spacer, HStack, Icon } from '@chakra-ui/react'
import { ChevronLeft, ZoomIn, ZoomOut } from 'react-feather'

import { useQuery, useImpersonate, useUserPreferences } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import ActionsMenu from './Components/ActionsMenu'
import TemplateName from './Components/TemplateName'
import TemplateContent from './Components/TemplateContent'

const Template = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const compactView = useUserPreferences((state) => state.compactView)
  const toggleCompactView = useUserPreferences((state) => state.toggleCompactView)

  const impersonateData = useImpersonate((state) => state.impersonateData)
  const isSharable = impersonateData.impersonating

  const { data, isLoading, hasError } = useQuery(`api/v1/templated_schedules/${id}`)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (hasError) return <Error />

  if (!Object.keys(data).length) {
    return <Error />
  }

  return (
    <Box paddingBottom={20}>
      <Box px="4">
        <Box display="flex" alignItems="center" my="8">
          <Link onClick={() => navigate('/coaching/templates')} color="gray">
            <ChevronLeft size="18px" />
          </Link>
          <Spacer />
          <Box maxWidth="400px" width="100%">
            <TemplateName id={data.id} name={data.name} />
          </Box>
          <Spacer />
          <HStack spacing="24px">
            <Box cursor="pointer" height="20px" onClick={() => toggleCompactView()}>
              {compactView ? (
                <Icon
                  as={ZoomIn}
                  w="5"
                  h="5"
                  color="gray.500"
                  strokeWidth="1px"
                  _hover={{ cursor: 'pointer', color: 'brand.500' }}
                />
              ) : (
                <Icon
                  as={ZoomOut}
                  w="5"
                  h="5"
                  color="gray.500"
                  strokeWidth="1px"
                  _hover={{ cursor: 'pointer', color: 'brand.500' }}
                />
              )}
            </Box>
            <ActionsMenu {...{ id, isSharable }} />
          </HStack>
        </Box>
        <TemplateContent template={data} />
      </Box>
    </Box>
  )
}

export default Template
