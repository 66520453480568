import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Spacer,
  Grid,
  useDisclosure,
  HStack,
} from '@chakra-ui/react'
import { Edit3, Target, Activity, XCircle, PlusCircle } from 'react-feather'

import Select from 'react-select'
import { format, isMonday } from 'date-fns'

import { useImpersonate, useBulkAddWorkout, useQuery } from 'hooks'

import securedApi from 'backend/axios'

import { groupNonArchivedWorkouts, processApiError } from 'helpers/utils'

import { useDailyMetrics } from 'domain/DailyMetrics/hooks'
import CreateNote from 'domain/Notes/PlannerCreateNote'
import AddWeekLabel from 'domain/Planner/components/AddWeekLabel'

import LoadingSpinner from 'components/LoadingSpinner'
import Notify from 'components/Notification'
import Error from 'components/General/Error'

import SimpleModal from 'components/Modal'

import { customStyles } from './helpers'

const DayActions = ({ day, refresh, isOpen, onClose, onAddWorkout }) => {
  const {
    isOpen: isClearModalOpen,
    onOpen: onClearModalOpen,
    onClose: onCloseModal,
  } = useDisclosure()

  const {
    isOpen: isWeekLabelOpen,
    onOpen: onWeekLabelOpen,
    onClose: onCloseWeekLabelOpen,
  } = useDisclosure()

  const [isAddingLoading, setIsAddingLoading] = useState(false)
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [noteStyle, setNoteStyle] = useState()

  const [isMenuOpen, setMenuOpen] = useState(false)

  const impersonateData = useImpersonate((state) => state.impersonateData)

  const setBulkAddWorkoutOpen = useBulkAddWorkout((state) => state.setBulkAddWorkoutOpen)
  const setBulkAddWorkoutDate = useBulkAddWorkout((state) => state.setBulkAddWorkoutDate)

  const setDailyMetricsVisible = useDailyMetrics((state) => state.setDailyMetricsVisible)
  const setDailyMetricsDate = useDailyMetrics((state) => state.setDailyMetricsDate)

  const formattedDate = format(day, 'yyyy-MM-dd')

  const { data, isLoading, hasError } = useQuery('api/v1/activities/add_list')

  // TODO: this could use a streamlined endpoint
  const {
    data: dayData,
    isLoading: isDayDataLoading,
    hasError: hasDayDataError,
  } = useQuery(`api/v1/schedules/user_day?date=${formattedDate}`)

  if (hasError || hasDayDataError) {
    return <Error />
  }

  const handleSearchItemSelected = (event) => {
    setIsAddingLoading(true)
    onAddWorkout({
      id: event.id,
    })
  }

  const handleRecentItemSelected = (id) => {
    setIsAddingLoading(true)
    onAddWorkout({
      id,
    })
  }

  const handleOpenBulkAddWorkout = () => {
    setBulkAddWorkoutDate(day)
    setBulkAddWorkoutOpen()
    onClose()
  }

  const displayNoteCreation = ({ style }) => {
    setCreateNoteOpen(true)
    setNoteStyle(style)
  }

  const handleNoteCreation = () => {
    refresh()
    setCreateNoteOpen(false)
    onClose()
  }

  const handleDisplayDailyMetrics = () => {
    setDailyMetricsDate(formattedDate)
    setDailyMetricsVisible()
    onClose()
  }

  const handleRestDay = () => {
    securedApi
      .post(`api/v1/scheduled_notes`, {
        title: 'Rest day',
        style: 'rest',
        scheduled_at: dayData.date,
      })
      .then(() => {
        Notify({ content: 'Marked as rest day', type: 'success' })
        refresh()
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleDeleteRestDay = () => {
    const restDayData = dayData.notes.find((item) => item.style === 'rest')

    securedApi
      .delete(`api/v1/scheduled_notes/${restDayData.id}`)
      .then(() => {
        refresh()
        Notify({
          content: 'Rest day removed',
          type: 'success',
        })
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  const handleClearWeek = () => {
    securedApi
      .post(`api/v1/schedules/clear_week?week_start_date=${formattedDate}`)
      .then(() => {
        refresh()
        Notify({ content: 'Week cleared', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => {
        onCloseModal()
        onClose()
      })
  }

  const handleCopyWeek = () => {
    securedApi
      .post(`api/v1/schedules/copy_week?week_start_date=${formattedDate}&mode=insert`)
      .then(() => {
        refresh()
        Notify({ content: 'Week duplicated', type: 'success' })
        onClose()
      })
      .catch((error) => processApiError(error))
  }

  // React Select component traps the esc key when focused. This helps close the modal with the esc key
  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && !isMenuOpen) {
      e.stopPropagation()
      onClose()
    }
  }

  const handleCloseWeekLabel = () => {
    onCloseWeekLabelOpen()
    onClose()
  }

  const renderContent = () => {
    const currentDay = format(new Date(day), 'PPPP')
    const restDay = dayData?.notes?.find((i) => i.style === 'rest')
    const hasDailyMetrics = dayData?.biometrics?.present
    const defaultWeekLabelContent = dayData.label?.content
    const defaultWeekLabelId = dayData.label?.id

    const { activities, recent } = data

    const workoutOptions = groupNonArchivedWorkouts(activities)
    const recentWorkouts = recent

    return (
      <>
        <ModalHeader>
          <Text fontSize="sm">{currentDay}</Text>
        </ModalHeader>

        <ModalBody mb="4">
          <Box display="flex" alignItems="center" mb="2">
            <Box>
              <Text fontWeight="bold">Add Workout</Text>
            </Box>
            <Spacer />
            <Box>
              <Button size="xs" onClick={() => handleOpenBulkAddWorkout()}>
                Bulk Add
              </Button>
            </Box>
          </Box>
          {isAddingLoading ? (
            <LoadingSpinner margin="2" />
          ) : (
            <Select
              autoFocus
              onKeyDown={handleKeyDown}
              onMenuClose={handleMenuClose}
              onMenuOpen={handleMenuOpen}
              styles={customStyles}
              options={workoutOptions}
              placeholder="Search workouts..."
              closeMenuOnSelect={true}
              onChange={handleSearchItemSelected}
              noOptionsMessage={() => 'No workouts found'}
            />
          )}

          <Box mt="4">
            {recentWorkouts?.length > 0 && (
              <>
                <Box mb="2">
                  <Text fontSize="sm" fontWeight="semibold">
                    Recent Workouts
                  </Text>
                </Box>
                <HStack wrap="wrap" spacing="2" mb="4">
                  {recentWorkouts.map(({ id, category_hex_colour, name }) => (
                    <Button
                      key={id}
                      size="xs"
                      borderLeft="10px"
                      borderStyle="solid"
                      borderColor={category_hex_colour}
                      onClick={() => handleRecentItemSelected(id)}
                    >
                      {name}
                    </Button>
                  ))}
                </HStack>
              </>
            )}

            <Box>
              <Text fontSize="sm" fontWeight="semibold">
                Extra Actions
              </Text>
            </Box>

            <Grid
              templateColumns={{
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
              gap={4}
              mt="2"
            >
              {impersonateData.impersonating ? (
                <Button
                  leftIcon={<Edit3 strokeWidth="1px" size="16px" />}
                  size="xs"
                  onClick={() => displayNoteCreation({ style: 'coaching' })}
                >
                  Coaching note
                </Button>
              ) : (
                <Button
                  leftIcon={<Edit3 strokeWidth="1px" size="16px" />}
                  size="xs"
                  onClick={() => displayNoteCreation({ style: 'note' })}
                >
                  Add note
                </Button>
              )}

              <Button
                leftIcon={<Target strokeWidth="1px" size="16px" />}
                size="xs"
                onClick={() => displayNoteCreation({ style: 'milestone' })}
              >
                Add milestone
              </Button>
              <Button
                leftIcon={<Activity strokeWidth="1px" size="16px" />}
                size="xs"
                onClick={() => handleDisplayDailyMetrics()}
              >
                Daily metrics
              </Button>
              <Button
                leftIcon={
                  restDay ? (
                    <XCircle strokeWidth="1px" size="16px" />
                  ) : (
                    <PlusCircle strokeWidth="1px" size="16px" />
                  )
                }
                size="xs"
                onClick={() => (restDay ? handleDeleteRestDay() : handleRestDay())}
              >
                Rest day
              </Button>
            </Grid>
          </Box>

          {isMonday(day) && (
            <Box mt="4">
              <Box>
                <Text fontSize="sm" fontWeight="semibold">
                  Week Actions
                </Text>
              </Box>
              <Grid
                templateColumns={{
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                gap={4}
                mt="2"
              >
                <Button size="xs" onClick={onWeekLabelOpen}>
                  {defaultWeekLabelId ? 'Update week label ' : 'Add a week label'}
                </Button>
                <Button size="xs" onClick={onClearModalOpen}>
                  Clear week
                </Button>
                <Button size="xs" onClick={() => handleCopyWeek()}>
                  Duplicate week
                </Button>
              </Grid>
            </Box>
          )}
        </ModalBody>

        {createNoteOpen && (
          <CreateNote
            open={createNoteOpen}
            style={noteStyle}
            date={dayData.date}
            onClose={() => setCreateNoteOpen(false)}
            onSuccess={handleNoteCreation}
          />
        )}

        <AddWeekLabel
          date={formattedDate}
          isOpen={isWeekLabelOpen}
          onClose={handleCloseWeekLabel}
          {...{
            defaultWeekLabelContent,
            defaultWeekLabelId,
            refresh,
          }}
        />

        <SimpleModal
          isOpen={isClearModalOpen}
          closeModal={onCloseModal}
          onAccept={() => handleClearWeek()}
          title="Are you sure?"
          subTitle="This action can't be undone"
        />
      </>
    )
  }
  const isDataLoading = isLoading || isDayDataLoading

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>{isDataLoading ? <LoadingSpinner /> : renderContent()}</ModalContent>
    </Modal>
  )
}

export default DayActions
