import { Text } from '@chakra-ui/react'

import { NavItem } from 'domain/Navigation/Components/Links'

export const MainNavigation = () => {
  const links = [
    {
      to: '/dashboard',
      title: 'Dashboard',
      icon: 'dashboard',
    },
    {
      to: '/planner',
      title: 'Planner',
      icon: 'planner',
    },
    {
      to: '/workouts',
      title: 'Workouts',
      icon: 'workouts',
    },
    {
      to: '/plans',
      title: 'Plans',
      icon: 'plans',
    },
  ]

  return links.map(({ to, title, icon }) => <NavItem key={title} {...{ to, title, icon }} />)
}

export const SecondaryNavigation = () => {
  const links = [
    {
      to: '/metrics',
      title: 'Metrics',
      icon: 'metrics',
    },
    {
      to: '/goals',
      title: 'Goals',
      icon: 'goals',
    },
    {
      to: '/tests',
      title: 'Tests',
      icon: 'tests',
    },
  ]

  return (
    <>
      <Text fontSize="xs" color="gray.400" fontWeight="semibold" textTransform="uppercase" mt="4">
        Performance
      </Text>
      {links.map(({ to, title, icon }) => (
        <NavItem key={title} {...{ to, title, icon }} />
      ))}
    </>
  )
}
