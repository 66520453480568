import { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Button, Box, Flex, Grid, GridItem, Text, Spacer, Icon, useBoolean } from '@chakra-ui/react'
import { Plus } from 'react-feather'

import securedApi from 'backend/axios'
import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import { useTemplateNote, useTemplateActivityModal, useTemplateBulkAddWorkout } from '../hooks'

import TemplateNote from './TemplateNote'
import TemplateActivityModal from './TemplateActivityModal'
import ExtraDayDetails from './ExtraDayDetails'
import DraggableNote from './DraggableNote'
import DraggableWorkout from './DraggableWorkout'
import RowLabel from './RowLabel'
import DayActions from './DayActions'
import BulkAddWorkout from './DayActions/BulkAddWorkout/BulkAddWorkout'
import { workoutManipulation, noteManipulation, isRestDay } from './helpers'

const TemplateContent = ({ template }) => {
  const { id, data } = template

  const [isDayActionsModalOpen, setIsDayActionsModalOpen] = useState(false)
  const [selectedDay, setSelectedDay] = useState()

  const [isAddingWeek, setIsAddingWeek] = useBoolean()

  const templateNoteOpen = useTemplateNote((state) => state.templateNoteOpen)
  const setTemplateNoteClose = useTemplateNote((state) => state.setTemplateNoteClose)
  const templateNoteId = useTemplateNote((state) => state.templateNoteId)

  const templateActivityModalOpen = useTemplateActivityModal(
    (state) => state.templateActivityModalOpen
  )
  const setTemplateActivityModalClose = useTemplateActivityModal(
    (state) => state.setTemplateActivityModalClose
  )
  const templateActivityModalWorkoutId = useTemplateActivityModal(
    (state) => state.templateActivityModalWorkoutId
  )

  const bulkAddWorkoutOpen = useTemplateBulkAddWorkout((state) => state.bulkAddWorkoutOpen)

  const handleAddWorkoutToDay = ({ id: activityId }) => {
    securedApi
      .post(`api/v1/templated_scheduled_activities/new_calendar`, {
        templated_schedule_id: id,
        templated_activity_id: activityId,
        week: selectedDay.week,
        day: selectedDay.day,
      })
      .then(() => {
        revalidateLiveQueries()
      })
      .catch((error) => {
        processApiError(error, 'Error adding workout. Please try again.')
      })
      .finally(() => {
        setIsDayActionsModalOpen(false)
      })
  }

  const handleContextMenuDelete = (id) => {
    securedApi
      .delete(`api/v1/templated_scheduled_activities/${id}`)
      .then(() => {
        revalidateLiveQueries()
      })
      .catch((error) => {
        processApiError(error, 'Error deleting workout. Please try again.')
      })
  }

  const handleAddWeek = () => {
    setIsAddingWeek.on()
    securedApi
      .put(`api/v1/templated_schedules/${id}/add_week`)
      .then(() => {
        revalidateLiveQueries()
      })
      .catch((error) => {
        processApiError(error, 'Error adding week. Please try again.')
      })
      .finally(() => {
        setIsAddingWeek.off()
      })
  }

  const handleOnDragEnd = (result) => {
    if (result.type === 'NOTE') {
      noteManipulation({ result, data })
    } else {
      workoutManipulation({ result, data })
    }
  }

  const showDayActions = (day) => {
    setSelectedDay(day)
    setIsDayActionsModalOpen(true)
  }

  const handleCloseNote = () => {
    setTemplateNoteClose()
    revalidateLiveQueries()
  }

  const WeekDay = ({ provided, day }) => {
    return (
      <GridItem
        key={day.toString()}
        w="100%"
        bg={isRestDay(day) ? '#e5e5e5' : '#fff'}
        onClick={() => showDayActions(day)}
        _hover={{ cursor: 'pointer', background: 'rgba(255, 255, 255, 0.5)' }}
      >
        <Box display="flex" flexDirection="column" minH="32">
          <Box onClick={(e) => e.stopPropagation()} p="2">
            {day.content.map((item, idx) => (
              <DraggableWorkout
                key={idx}
                idx={idx}
                item={item}
                handleContextMenuDelete={handleContextMenuDelete}
              />
            ))}
            {provided.placeholder}
          </Box>
          <Spacer />
          <Box display="flex" justifyContent="end" p="1">
            <Icon
              as={Plus}
              strokeWidth="1px"
              w="4"
              h="4"
              color="gray.400"
              _hover={{ color: 'brand.500', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </GridItem>
    )
  }

  const renderTemplateData = () => {
    return data.map((day, index) => {
      return (
        <Box key={day.id} minW="130px">
          <RowLabel data={day} refresh={revalidateLiveQueries} />
          <Flex alignItems="center" height="6">
            <Text fontSize="x-small">{day.title.substring(0, 3)}</Text>
            <Spacer />
            <ExtraDayDetails {...{ data, day }} />
          </Flex>

          <Droppable
            droppableId={`note-${index.toString()}`}
            key={`${day.id}-${index}`}
            type="NOTE"
          >
            {(provided) => (
              <Box
                ref={provided.innerRef}
                style={{ minHeight: '1px' }}
                {...provided.droppableProps}
              >
                {day.notes.map((item, idx) => (
                  <DraggableNote key={idx} idx={idx} item={item} refresh={revalidateLiveQueries} />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>

          <Droppable droppableId={index.toString()} key={day.id}>
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? '' : '',
                }}
                {...provided.droppableProps}
              >
                <WeekDay {...{ provided, day }} />
              </Box>
            )}
          </Droppable>
        </Box>
      )
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Grid templateColumns="repeat(7, 1fr)" gap={6}>
          {renderTemplateData()}
        </Grid>
      </DragDropContext>
      <Box mt="4">
        <Button colorScheme="brand" onClick={handleAddWeek} isLoading={isAddingWeek}>
          Add Week
        </Button>
      </Box>
      {isDayActionsModalOpen && (
        <DayActions
          refresh={revalidateLiveQueries}
          templateId={id}
          day={selectedDay}
          isOpen={isDayActionsModalOpen}
          onClose={() => setIsDayActionsModalOpen(false)}
          onAddWorkout={handleAddWorkoutToDay}
        />
      )}
      {templateNoteOpen && (
        <TemplateNote
          noteId={templateNoteId}
          open={templateNoteOpen}
          onClose={handleCloseNote}
          getAndSetNewDayData={handleCloseNote}
        />
      )}
      {templateActivityModalOpen && (
        <TemplateActivityModal
          readOnly
          id={templateActivityModalWorkoutId}
          isOpen={templateActivityModalOpen}
          onClose={setTemplateActivityModalClose}
        />
      )}
      {bulkAddWorkoutOpen && <BulkAddWorkout templateId={id} />}
    </>
  )
}

export default TemplateContent
