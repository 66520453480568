import React, { useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { ChevronUp, ChevronDown, Edit, Check } from 'react-feather'

import { useForm, Controller } from 'react-hook-form'

import securedApi from 'backend/axios'
import { useQuery } from 'hooks'
import { revalidateLiveQueries } from 'helpers/swrConfig'

import { processApiError } from 'helpers/utils'

import Notify from 'components/Notification'
import Error from 'components/General/Error'
import LoadingSpinner from 'components/LoadingSpinner'
import HeaderDivider from 'components/General/HeaderDivider'
import RichTextArea from 'components/RichTextArea'
import InnerPageWrapperActions from 'components/General/InnerPageWrapperActions'

import { BadgeProject } from './components/BadgeProject'
import { GoalDates } from './components/GoalDates'
import { GoalLogbookEntriesTable } from './components/GoalLogbookEntriesTable'
import { GoalProjectStats } from './components/GoalProjectStats'

import LinkedItemDate from './components/LinkedItemDate'

import { statusMapText, statusMapColour, styleMapText } from './helpers'

const GoalsDetails = () => {
  const { id } = useParams()

  const [completing, setCompleting] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const { isOpen: isNotesOpen, onToggle: onNotesToggle } = useDisclosure({ defaultIsOpen: true })

  const { control, handleSubmit } = useForm()

  const { data, isLoading, hasError } = useQuery(`api/v1/goals/${id}`)

  if (hasError) return <Error />
  if (isLoading) return <LoadingSpinner />

  const goal = data
  const isProject = !!goal.project
  const isComplete = goal.status === 'complete'

  const scheduledActivities = data.scheduled_activities
  const logbook = data.logbook
  const statistics = data.statistics

  const milestoneData = data.scheduled_notes.filter((item) => item.style === 'milestone')
  const notesData = data.scheduled_notes.filter((item) => item.style === 'note')

  const milestones = milestoneData
  const notes = notesData

  const handleCompleteGoal = () => {
    setCompleting(true)
    securedApi
      .put(`/api/v1/goals/${id}`, { status: 'complete' })
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Goal has been completed', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error completing goal. Please try again.')
      })
      .finally(() => {
        setCompleting(false)
      })
  }

  const handleUnlinkScheduledActivities = (activityId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_activity_id]=${activityId}`)
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Activity has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking activity. Please try again.')
      })
  }

  const handleNoteUnlinkGoal = (noteId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_note_id]=${noteId}`)
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Note has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking note. Please try again.')
      })
  }

  const handleMilestoneUnlinkGoal = (milestoneId) => {
    securedApi
      .put(`/api/v1/goals/${id}/remove_goalable?link[scheduled_note_id]=${milestoneId}`)
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Milestone has been unlinked', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error unlinking milestone. Please try again.')
      })
  }

  const renderNoLinks = () => {
    if (
      scheduledActivities.length ||
      milestones.length ||
      notes.length ||
      logbook?.entries?.length
    ) {
      return null
    } else {
      return (
        <Box mt="8" background="white" padding="6" maxW="600px" margin="20px auto">
          <Heading as="h4" size="sm">
            No items linked
          </Heading>
          <Box mt="4">
            <Text color="gray.500">
              You haven't linked any workouts, notes or milestones to this goal yet.
            </Text>
            <Link
              href="https://docs.sequence-app.com/features/goals"
              isExternal
              fontWeight="bold"
              color="yellow.500"
            >
              Learn how <ExternalLinkIcon />
            </Link>
          </Box>
        </Box>
      )
    }
  }

  const renderScheduledActivities = () => {
    if (!scheduledActivities.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Workouts" />

        {scheduledActivities.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Text display="inline" fontWeight="bold">
                  {item.activity_name}
                </Text>
                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleUnlinkScheduledActivities(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text color="gray.500" fontSize="sm">
                {item.notes}
              </Text>
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderMilestones = () => {
    if (!milestones.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Milestones" />

        {milestones.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Link as={NavLink} to={`/milestone/${item.id}`} variant="brand" fontWeight="bold">
                  {item.title}
                </Link>
                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleMilestoneUnlinkGoal(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text
                color="gray"
                my="4"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderNotes = () => {
    if (!notes.length) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Linked Notes" />

        {notes.map((item) => (
          <Stack key={item.id} mt="4">
            <Box background="white" padding="6" width="100%" maxW="500px" margin="0 auto">
              <Box>
                <Link as={NavLink} to={`/note/${item.id}`} variant="brand" fontWeight="bold">
                  {item.title}
                </Link>

                <Button
                  ml="4"
                  size="xs"
                  fontWeight="bold"
                  variant="outline"
                  onClick={() => handleNoteUnlinkGoal(item.id)}
                >
                  Unlink
                </Button>
              </Box>
              <LinkedItemDate date={item.scheduled_at} />
              <Text
                color="gray"
                my="4"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </Box>
          </Stack>
        ))}
      </Box>
    )
  }

  const renderLogbookEntries = () => {
    if (!isProject) {
      return null
    }

    return (
      <Box mt="8">
        <HeaderDivider heading="Project Stats" />
        <Box mb="4">
          <GoalProjectStats {...{ statistics }} />
        </Box>
        <HeaderDivider heading="Logbook Entries" />
        <Box mb="4">
          <GoalLogbookEntriesTable {...{ logbook }} />
        </Box>
      </Box>
    )
  }

  const onSubmit = ({ notes }) => {
    setProcessing(true)

    securedApi
      .put(`api/v1/goals/${id}`, {
        notes: notes,
      })
      .then(() => {
        Notify({ content: 'Beta updated', type: 'success' })
      })
      .catch((error) => {
        processApiError(error, 'Error updating beta. Please try again.')
      })
      .finally(() => {
        setProcessing(false)
        // onEditingToggle() if using click and change mode
        revalidateLiveQueries()
      })
  }

  const renderGoal = ({ id, title, status, style, details, start_date, end_date, notes }) => (
    <>
      <Card rounded="0" key={id} my="8">
        <CardHeader pb="0">
          <Flex justifyContent="space-between">
            <Box>
              <Heading size="md">{title}</Heading>
              <GoalDates startDate={start_date} endDate={end_date} />
            </Box>
            <Flex flexDirection="column" gap={2} alignItems="flex-end">
              <Badge colorScheme={statusMapColour[status]}>{statusMapText[status]}</Badge>
              <Badge>{styleMapText[style]}</Badge>
              {isProject && <BadgeProject ml="2" />}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody pt="4">
          <Box>
            <Text
              color="gray"
              dangerouslySetInnerHTML={{
                __html: details,
              }}
            />
          </Box>
          {isProject && (
            <Box mt="4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl mt="6">
                  <FormLabel
                    display="flex"
                    alignItems="center"
                    onClick={onNotesToggle}
                    _hover={{ cursor: 'pointer' }}
                  >
                    Beta{' '}
                    <Text as="span" fontSize="sm" color="gray.500" ml="2">
                      (Keep up to date beta for your project here)
                    </Text>
                    <Icon
                      as={isNotesOpen ? ChevronUp : ChevronDown}
                      w="5"
                      h="5"
                      color="gray.500"
                      ml="1"
                      strokeWidth="1px"
                      _hover={{ cursor: 'pointer', color: 'brand.500' }}
                    />
                  </FormLabel>
                  <Collapse in={isNotesOpen} animateOpacity>
                    <Controller
                      name="notes"
                      defaultValue={notes}
                      control={control}
                      render={({ field }) => <RichTextArea {...field} />}
                    />

                    <ButtonGroup
                      spacing="4"
                      marginTop="4"
                      display="flex"
                      alignItems="center"
                      width="100%"
                    >
                      <Spacer />
                      <Button type="submit" colorScheme="brand" isLoading={processing}>
                        Save
                      </Button>
                    </ButtonGroup>
                  </Collapse>
                </FormControl>
              </form>
            </Box>
          )}
        </CardBody>
      </Card>

      {renderNoLinks()}
      {renderLogbookEntries()}
      {renderScheduledActivities()}
      {renderNotes()}
      {renderMilestones()}
    </>
  )

  return (
    <Container maxW="container.lg">
      <InnerPageWrapperActions backAction="/goals">
        <Flex height="24px" alignItems="center" gap={3}>
          {/*{!isComplete && (
            <Box>
              <Button
                size="sm"
                colorScheme="brand"
                variant="outline"
                isLoading={completing}
                onClick={handleCompleteGoal}
              >
                Complete Goal
              </Button>
            </Box>
          )}
          <Box>
            <Button
              size="sm"
              colorScheme="brand"
              variant="outline"
              // isLoading={completing}
              // onClick={handleCompleteGoal}
            >
              <Link as={NavLink} to={`/goals/edit/${goal.id}`} variant="brand">
                Edit Goal
              </Link>
            </Button>
          </Box>*/}
          {completing ? (
            <LoadingSpinner margin="4px" size="sm" color="brand.500" />
          ) : isComplete ? null : (
            <Tooltip label="Complete goal">
              <Link
                onClick={handleCompleteGoal}
                // variant="brand"
                color="gray"
                _hover={{ color: 'green.400' }}
                fontWeight="bold"
              >
                <Check size="20px" />
              </Link>
            </Tooltip>
          )}
          <Tooltip label="Edit goal">
            <Link
              as={NavLink}
              to={`/goals/edit/${goal.id}`}
              color="gray"
              _hover={{ color: 'brand.500' }}
              fontWeight="bold"
            >
              <Edit size="20px" />
            </Link>
          </Tooltip>
        </Flex>
      </InnerPageWrapperActions>
      <Box marginBottom="20">{renderGoal(goal)}</Box>
    </Container>
  )
}

export default GoalsDetails

/*


  // if using click and change mode
  const { isOpen: isEditing, onToggle: onEditingToggle } = useDisclosure()


  // // if using click and change mode
  // const onCancelEdit = () => {
  //   alert('TODO: check for changes')
  //   if (isDirty) {
  //   } else {
  //     reset()
  //     onEditingToggle()
  //   }
  // }

<Text>Beta:</Text>
            {isEditing ? (
              <Box pt="2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl>
                    <Box>
                      <Controller
                        name="notes"
                        defaultValue={notes}
                        control={control}
                        render={({ field }) => <RichTextArea {...field} />}
                      />

                      <ButtonGroup spacing="4" marginTop="4" display="flex" width="100%">
                        <Spacer />
                        <Button variant="outline" onClick={onCancelEdit}>
                          Cancel
                        </Button>
                        <Button type="submit" colorScheme="brand" isLoading={processing}>
                          Save
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            ) : notes ? (
              <Text
                color="gray"
                dangerouslySetInnerHTML={{
                  __html: notes,
                }}
                style={{ padding: 15, cursor: 'pointer' }}
                onClick={onEditingToggle}
              />
            ) : (
              <Text color="gray" fontStyle="italic">
                Add and update the beta for your project here
              </Text>
            )}*/
// ,reset if using click change mode
