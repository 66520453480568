import { Spacer, Box } from '@chakra-ui/react'

import RestDayAction from './RestDayAction'

const ExtraDayDetails = ({ data, day }) => {
  const { notes } = day

  const showRestDay = notes.find((i) => i.style === 'rest')

  return (
    <Box key={day.id} display="flex" my="2" width="100%">
      <Spacer />
      {showRestDay && <RestDayAction {...{ notes }} />}
    </Box>
  )
}

export default ExtraDayDetails
