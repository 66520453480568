import { create } from 'zustand'

const useTemplateBulkAddWorkout = create((set) => ({
  bulkAddWorkoutOpen: false,
  bulkAddWorkoutDate: new Date(),
  setBulkAddWorkoutOpen: () => set((state) => ({ bulkAddWorkoutOpen: !state.bulkAddWorkoutOpen })),
  setBulkAddWorkoutDate: (value) => set(() => ({ bulkAddWorkoutDate: value })),
}))

export default useTemplateBulkAddWorkout
