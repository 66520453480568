import { Box, Spinner } from '@chakra-ui/react'

const FullPageLoader = () => {
  return (
    <Box
      width="full"
      height="100vh"
      zIndex="9999"
      position="absolute"
      top="0"
      left="0"
      background="gray.50"
    >
      <Spinner position="relative" marginX="auto" display="block" top="50%" />
    </Box>
  )
}

export default FullPageLoader
