import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Scatter,
  ScatterChart,
  Cell,
  CartesianGrid,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  calcXYDomains,
  calcXTicks,
  calcYTicks,
  calcYDomain,
  calcYMaxSum,
  getDataValues,
  getLabelValues,
  tickFormatterDate,
} from 'helpers/charts'

import { CustomTooltipValue, TickWeekLabel } from 'domain/Metrics/CommonMeasure'

import { HighlightAndZoomLineChart } from 'domain/Metrics/components/HighlightAndZoomLineChart'
import { pickStyleMapping, styleColourMapping } from 'domain/Widgets/Logbook/constants'

import { max, upperFirst } from 'lodash'

export const GoalProjectStats = ({ statistics }) => {
  const {
    num_attempts,
    num_work,
    num_total_attempts,
    num_sessions,
    num_sessions_past_month,
    dates,
  } = statistics.entries
  const { annotations, entries } = dates

  // console.log('annotations', annotations)
  // console.log('entries', entries)

  // To get attempts working, will need to change metrics
  // to include attempts (like in WidgetLogbook#metrics_by_grade_by_style)
  // and probably include the logbook with styles (like EntriesByStyle)
  const dataKey = 'sessions'
  // const dataKey = 'attempts'

  const attemptStyles = ['attempt', 'work']

  const { y_values } = getDataValues(entries, dataKey)
  const { x_ticks } = calcXTicks(annotations.range)
  const { xy_domains } = calcXYDomains(annotations, y_values)
  const { week_labels } = annotations

  // console.log('xy_domains', xy_domains)
  // console.log('x_ticks', x_ticks)
  // console.log('week_labels', week_labels)

  const chartContext = upperFirst(dataKey)

  // let chartData
  // if (dataKey === 'sessions') {
  //   chartData = entries
  // } else {
  //   chartData = entries.map((entry) => {
  //     const mapped = {
  //       ...entry,
  //       ...entry.attempts,
  //     }
  //     delete mapped.attempts
  //     delete mapped.sessions
  //     return mapped
  //   })
  // }
  // console.log('chartData', chartData)

  const renderChart = () => {
    /*<HighlightAndZoomLineChart initialData={initialData} />*/
    /*<HighlightAndZoomLineChart initialData={entries} dataKeyX="date_value" dataKeyY="value" />*/
    return (
      <Flex flexDirection="column" flexGrow="1">
        <Text fontWeight="bold" textAlign="center">
          {chartContext}
        </Text>
        <ResponsiveContainer height={150} width="100%">
          <ScatterChart
            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
            data={entries}
            barCategoryGap="20"
            barSize="2"
            stackOffset="expand"
          >
            <XAxis
              type="number"
              dataKey="date_value"
              scale="time"
              allowDuplicatedCategory
              domain={[xy_domains.x_domain_min, xy_domains.x_domain_max]}
              tick={<TickWeekLabel weekLabels={week_labels} />}
              ticks={x_ticks}
              tickFormatter={tickFormatterDate}
            />
            <YAxis domain={[0, max(y_values) + 0.2]} hide={true} />
            {dataKey === 'sessions' ? (
              <Scatter dataKey={dataKey} fill="#82ca9d" />
            ) : (
              attemptStyles.map((style) => (
                <Scatter key={style} dataKey={style} fill={styleColourMapping[style]} />
              ))
            )}
            <CartesianGrid strokeDasharray="3 3" />
            <ChartTooltip
              cursor={{ fill: '#aabbcc33' }}
              content={<CustomTooltipValue name={chartContext} dataKey={dataKey} data={entries} />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Flex>
      // {/*<Text>Sessions / Attempts toggle</Text>*/}
    )
  }

  const renderStats = () => {
    return (
      <Flex width="50%" flexGrow="1" alignItems="top" justifyContent="center" padding={4}>
        <Flex
          flexDirection="column"
          flexGrow="1"
          alignItems="center"
          justifyContent="flex-start"
          padding={4}
        >
          <Box>
            <Text fontWeight="bold">Sessions</Text>
          </Box>
          <Box>Total: {num_sessions}</Box>
          <Box>Past month: {num_sessions_past_month}</Box>
        </Flex>
        <Flex
          flexDirection="column"
          flexGrow="1"
          alignItems="center"
          justifyContent="flex-start"
          padding={4}
        >
          <Box>
            <Text fontWeight="bold">Attempts</Text>
          </Box>
          <Box>Total: {num_total_attempts}</Box>
          <Box>Working: {num_work}</Box>
          <Box>Redpoint: {num_attempts}</Box>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex background="white" padding="4" width="90%" margin="0 auto" flexDirection="row">
      {renderChart()}
      {renderStats()}
    </Flex>
  )
}
