import React, { useState, useRef } from 'react'
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Icon,
  FormControl,
  Table,
  Thead,
  Tbody,
  Tr,
  TableContainer,
  useDisclosure,
} from '@chakra-ui/react'
import { useForm, FormProvider } from 'react-hook-form'

import { List as FullLogbookIcon } from 'react-feather'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { HeaderRow, LogbookRows, CreateEditRow, WidgetLogbookModal } from './components'

import { ActivityModalHeader } from 'domain/Widgets/components'

import { tableWidths } from 'domain/Widgets/Logbook/constants'
import { useLogbookMethods } from 'domain/Widgets/Logbook/hooks'

const WidgetLogbooks = ({
  logbooks,
  onSetLogbooks,
  scheduledActivityId,
  highlightLogbookEntryId,
  readOnly,
  triggerRerender,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  // for now have all hook methods passed from the top level
  // because updating a state (e.g. setEditId) in a lower component (e.g. ContentRow)
  // is not being reflected in a higher component (e.g. LogbookRows)
  const methods = useForm()
  const {
    isAdding,
    isEditing,
    editId,
    setEditId,
    showEditButton,
    isNameFocused,
    isNotesFocused,
    handleAddAttempt,
    handleAddEntry,
    handleCancelEdit,
    handleCreateProjectGoalFromEntry,
    handleEditEntry,
    handleDiscardNewEntry,
    handleDiscardEditEntry,
    handleDeleteLogbookEntry,
    handleMoveLogbookEntry,
    handleSubmitLogbookEntry,
  } = useLogbookMethods(methods)

  const onSubmit = (logbook, values) => {
    // this handles both create/edit
    handleSubmitLogbookEntry({
      scheduledActivityId,
      logbook,
      logbooks,
      onSetLogbooks,
      entry: values,
    })
  }

  // logbook to pass to modal
  const [, setLogbook] = useState(null)

  // this might only have been used for the copy to clipboard function?
  const tableRef = useRef(null)

  if (!logbooks?.length) {
    return null
  }

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result

    // dropped outside the list, or to same place in the list
    if (!destination || destination.index === source.index) {
      return
    }

    const logbookId = destination.droppableId
    const entryId = draggableId
    const originalPosition = source.index
    const reorderedPosition = destination.index

    handleMoveLogbookEntry({
      entryId,
      onSetLogbooks,
      logbooks,
      logbookId,
      originalPosition,
      reorderedPosition,
    })
  }

  const ActionButton = ({ text, onClick, variant = 'outline' }) => {
    return (
      <Flex justifyContent="center">
        <Button cursor="pointer" onClick={onClick} variant={variant} colorScheme="brand" size="xs">
          {text}
        </Button>
      </Flex>
    )
  }

  const AddEntryButton = () => {
    if (!(isAdding || isEditing)) {
      return <ActionButton text="Add entry" onClick={handleAddEntry} />
    }
  }

  const OpenFullLogbookIcon = ({ logbook }) => {
    return (
      <Link
        as="span"
        color="brand.600"
        onClick={() => {
          setLogbook(logbook)
          onOpen()
        }}
        _hover={{ cursor: 'pointer', color: 'brand.500' }}
      >
        <Icon as={FullLogbookIcon} w={5} h={5} pl={1} pt={1} />
      </Link>
    )
  }

  return (
    <>
      <ActivityModalHeader label="Logbook" />

      {logbooks.map((logbook) => {
        const showTable = logbook.entries.length > 0 || isAdding

        return (
          <FormProvider key={logbook.id} {...methods}>
            <form onSubmit={methods.handleSubmit((values) => onSubmit(logbook, values))}>
              <FormControl>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Box key={logbook.name} mb={3}>
                    <Flex flexDirection="row" alignItems="center" mb="1">
                      <Text fontSize="sm">{logbook.name}</Text>
                      <OpenFullLogbookIcon logbook={logbook} />
                    </Flex>

                    {showTable && (
                      <TableContainer maxWidth={tableWidths.table} marginBottom={4}>
                        <Table variant="simple" size="sm">
                          <Thead>
                            <HeaderRow {...{ logbook, isAdding, isEditing, triggerRerender }} />
                          </Thead>

                          <Droppable droppableId={logbook.id}>
                            {(droppableProvided) => (
                              <Tbody
                                ref={(ref) => {
                                  tableRef.current = ref
                                  droppableProvided.innerRef(ref)
                                }}
                                {...droppableProvided.droppableProps}
                              >
                                <LogbookRows
                                  {...{
                                    editId,
                                    handleAddAttempt,
                                    handleCancelEdit,
                                    handleCreateProjectGoalFromEntry,
                                    handleDeleteLogbookEntry,
                                    handleDiscardEditEntry,
                                    handleDiscardNewEntry,
                                    handleEditEntry,
                                    handleSubmitLogbookEntry,
                                    highlightLogbookEntryId,
                                    isEditing,
                                    isNameFocused,
                                    isNotesFocused,
                                    logbook,
                                    logbooks,
                                    onSetLogbooks,
                                    onSubmit,
                                    scheduledActivityId,
                                    setEditId,
                                    showEditButton,
                                  }}
                                />
                                {isAdding && (
                                  <Tr height={tableWidths.row}>
                                    <CreateEditRow
                                      // this component will interpret an
                                      // empty object as a new entry and adjust accordingly
                                      entry={{}}
                                      {...{
                                        handleCancelEdit,
                                        handleDeleteLogbookEntry,
                                        handleDiscardEditEntry,
                                        handleDiscardNewEntry,
                                        handleSubmitLogbookEntry,
                                        isEditing,
                                        isNameFocused,
                                        isNotesFocused,
                                        logbook,
                                        logbooks,
                                        onSetLogbooks,
                                        onSubmit,
                                        scheduledActivityId,
                                      }}
                                    />
                                  </Tr>
                                )}
                                {droppableProvided.placeholder}
                              </Tbody>
                            )}
                          </Droppable>
                        </Table>
                      </TableContainer>
                    )}
                    <AddEntryButton />
                    <WidgetLogbookModal {...{ isOpen, onOpen, onClose, logbook }} />
                  </Box>
                </DragDropContext>
              </FormControl>
            </form>
          </FormProvider>
        )
      })}
    </>
  )
}

export default WidgetLogbooks
