import { create } from 'zustand'

const useTemplateNote = create((set) => ({
  templateNoteOpen: false,
  templateNoteId: null,
  setTemplateNoteOpen: () => set(() => ({ templateNoteOpen: true })),
  setTemplateNoteClose: () => set(() => ({ templateNoteOpen: false })),
  setTemplateNoteId: (value) => set(() => ({ templateNoteId: value })),
}))

export default useTemplateNote
