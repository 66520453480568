import React, { useEffect, useState } from 'react'
import {
  Box,
  Badge,
  Flex,
  Textarea,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Tooltip,
  Stack,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError, processApiError } from 'helpers/utils'

const MenstrualCycleModal = ({ isOpen, onClose, day, data, refreshPlannerData }) => {
  const [loading, setLoading] = useState(true)
  const [updatingStage, setUpdatingStage] = useState(false)
  const [stageText, setStageText] = useState(null)
  const [isChangingCycleStage, setIsChangingCycleStage] = useState(false)
  const [currentNotesValue, setCurrentNotesValue] = useState(null)
  const [biometricsMenstrualData, setBiometricsMenstrualData] = useState({})

  const date = day === undefined ? null : format(day, 'yyyy-MM-dd')
  const dayData = data.find((workout) => workout.date === date)

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      getDayMenstrualCycleData()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setStageText(dayData.biometrics.stage)
    }
  }, [isOpen, dayData])

  const getDayMenstrualCycleData = () => {
    securedApi
      .get(`api/v1/biometrics/day?date=${date}`)
      .then(({ data }) => {
        const filtered = data.content.find((metric) => metric.style === 'menstrual_start')

        setBiometricsMenstrualData(filtered)
      })
      .catch((error) => {
        processGetError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStageValue = (stage) => {
    switch (stage) {
      // don't set select default to match start of cycle
      // so that is more intuitive to switch from the blank option
      // to "Start" when changing from predicted to actual
      // case 'Menstruation':
      //   return 1;
      case 'Folicular':
        return 2
      case 'Luteal':
        return 3
      case 'Ovulation':
        return 4
    }
  }

  const getStageText = (stage) => {
    switch (stage) {
      case '-1':
        return 'Loading...'
      case '1':
        return 'Menstruation'
      case '2':
        return 'Folicular'
      case '3':
        return 'Luteal'
      case '4':
        return 'Ovulation'
    }
  }

  if (!isOpen) {
    return null
  }

  const renderModalContent = () => {
    const { biometrics } = dayData
    const { biometric_attribute_id, records } = biometricsMenstrualData
    const record = records.length ? records[0] : null
    const defaultValueNotes = record ? record.notes : null

    const handleSaveMenstrualNotes = ({ event }) => {
      const { value } = event.currentTarget || event

      if (currentNotesValue === value) {
        // value is the same
        return
      }

      securedApi
        .post(`/api/v1/biometric_records/menstrual`, {
          biometric_attribute_id: biometric_attribute_id,
          date,
          notes: value,
        })
        .then(() => {})
        .catch((error) => processApiError(error))
    }

    const handleSaveMenstrualValue = (value) => {
      // spinner while posting
      // optimistically update the text, otherwise have to wait for planner refresh
      setUpdatingStage(true)
      setStageText(getStageText(value))

      securedApi
        .post(`/api/v1/biometric_records/menstrual`, {
          biometric_attribute_id: biometric_attribute_id,
          date,
          value: value,
        })
        .then(() => {
          refreshPlannerData()
          getDayMenstrualCycleData()
        })
        .catch((error) => processApiError(error))
        .finally(() => setUpdatingStage(false))

      // if value was cleared, then close modal immediately
      if (value.toString() === '-1') {
        onClose()
      }
    }

    const CycleStage = ({ stage, includeReset }) => {
      if (updatingStage) {
        return <LoadingSpinner margin="1" size="xs" />
      } else if (isChangingCycleStage) {
        const includeRemove = stage === 'Menstruation' // to change setting start
        const defaultValue = getStageValue(stage)

        return (
          <Select
            data-allow-click
            style={{ marginTop: -10 }}
            size="sm"
            variant="outline"
            onChange={(event) => {
              const value = event.nativeEvent.target.value
              handleSaveMenstrualValue(value)
              setIsChangingCycleStage(false)
            }}
            onBlur={(event) => {
              setIsChangingCycleStage(false)
            }}
            defaultValue={defaultValue}
          >
            <option disabled selected value>
              {' '}
              -- Select an option --{' '}
            </option>
            {includeReset && <option value="-1">(reset)</option>}
            {includeRemove && <option value="-1">(remove)</option>}
            <option value="1">Start</option>
            <option value="2">Folicular</option>
            <option value="4">Ovulation</option>
            <option value="3">Luteal</option>
          </Select>
        )
      } else {
        return (
          <Box>
            <Text
              data-allow-click
              as="span"
              cursor="pointer"
              borderBottomColor="brand.600"
              borderBottomWidth="1px"
              style={{ color: '#060606' }}
              onClick={() => setIsChangingCycleStage(true)}
            >
              {stageText}
            </Text>
          </Box>
        )
      }
    }

    const UserSetValue = () => {
      return (
        <Tooltip label="You set this value manually">
          <Text cursor="pointer" ml={1}>
            *
          </Text>
        </Tooltip>
      )
    }

    const { cycle_start, cycle_day, stage, user_set, predicted } = biometrics

    return (
      <>
        {updatingStage && <ModalOverlay />}
        <Stack spacing="2" mb="4">
          <Text fontSize="sm">{format(day, 'PPPP')}</Text>

          <Flex justifyContent="space-between">
            {cycle_start ? (
              <Text fontWeight="bold" fontStyle="italic">
                Start of cycle
              </Text>
            ) : (
              <Text>
                Day of cycle:{' '}
                <Text as="span" fontWeight="bold">
                  {cycle_day}
                </Text>
              </Text>
            )}
            {predicted && (
              <Box>
                <Tooltip label="This is based off the last time you input the start of your cycle">
                  <Badge variant="outline" cursor="pointer" colorScheme="blue" mb="2">
                    Predicted
                  </Badge>
                </Tooltip>
              </Box>
            )}
          </Flex>

          <Flex minHeight="8">
            <Text mr="1">Stage:</Text>
            <CycleStage stage={stage} includeReset={user_set} />
            {user_set && <UserSetValue />}
          </Flex>

          <Box>
            <Text fontSize="sm">Notes</Text>
            <Textarea
              defaultValue={defaultValueNotes}
              onFocus={(event) => setCurrentNotesValue(event.currentTarget.value)}
              onBlur={(event) =>
                handleSaveMenstrualNotes({
                  event,
                })
              }
            />
          </Box>
        </Stack>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false} // might be beneficial?
      autoFocus={false}
      size="md"
    >
      <ModalOverlay />
      <ModalContent
        onClick={(event) => {
          // any click outside select box or stage text
          if (event.nativeEvent.target.dataset.allowClick === undefined) {
            setIsChangingCycleStage(false)
          }
        }}
      >
        <ModalHeader pb="0">Menstrual Cycle</ModalHeader>

        <ModalBody>{loading ? <LoadingSpinner /> : renderModalContent()}</ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}

export default MenstrualCycleModal
