import { create } from 'zustand'

const useActivityModal = create((set) => ({
  activityModalOpen: false,
  activityModalWorkoutId: null,
  preventActivityModalClose: false,
  setPreventActivityModalClose: (value) => set(() => ({ preventActivityModalClose: value })),
  setActivityModalOpen: () => set(() => ({ activityModalOpen: true })),
  setActivityModalClose: () => set(() => ({ activityModalOpen: false })),
  setActivityModalWorkoutId: (value) => set(() => ({ activityModalWorkoutId: value })),
}))

export default useActivityModal
