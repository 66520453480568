import { useQuery } from 'hooks'
import LoadingSpinner from 'components/LoadingSpinner'

import ClimbStrongMembershipPage from './ClimbStrongMembership'
import DefaultPlansPage from './DefaultPlansPage'

const CLIMB_STRONG_MEMBERSHIP = 'membership-climbstrong'

const Plans = () => {
  const { data, isLoading } = useQuery('api/v1/purchases')

  if (isLoading) {
    return <LoadingSpinner />
  }

  const hasPurchasedPlans = !!data.length

  const hasClimbStrongMembership = data.some(
    (purchase) => purchase.code === CLIMB_STRONG_MEMBERSHIP
  )

  if (hasClimbStrongMembership) {
    return <ClimbStrongMembershipPage />
  }

  return <DefaultPlansPage {...{ hasPurchasedPlans }} />
}

export default Plans
