import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import LoadingSpinner from 'components/LoadingSpinner'
import { processGetError } from 'helpers/utils'

import { EntriesTable } from 'domain/Widgets/Logbook/components'

const WidgetLogbookModal = ({ isOpen, onOpen, onClose, logbook }) => {
  const [previousLoading, setPreviousLoading] = useState(false)
  const [logbookData, setLogbookData] = useState([])

  useEffect(() => {
    setPreviousLoading(true)

    if (isOpen) {
      securedApi
        .get(`api/v1/widget_logbooks/${logbook.id}/modal`)
        .then(({ data }) => {
          setLogbookData(data)
        })
        .catch((error) => {
          processGetError(error)
        })
        .finally(() => {
          setPreviousLoading(false)
        })
    }
  }, [isOpen, logbook])

  const renderModalContent = () => {
    if (previousLoading) {
      return <LoadingSpinner />
    }

    if (logbookData.length === 0) {
      return (
        <Box>
          <Text>Workout has not been completed yet</Text>
        </Box>
      )
    }

    const { entries } = logbookData

    if (entries.length === 0) {
      return (
        <Box>
          <Text>No entries have been recorded yet</Text>
        </Box>
      )
    }

    return (
      <Box>
        <EntriesTable data={entries} {...{ logbook }} />
      </Box>
    )
  }

  return (
    <Modal scrollBehavior="inside" {...{ isOpen, onClose }} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{logbook.name}</ModalHeader>
        <ModalBody paddingBottom="6">{renderModalContent(logbook)}</ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}

export default WidgetLogbookModal
