import React, { useState } from 'react'
import { Box, Checkbox, Grid, GridItem, Text } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import securedApi from 'backend/axios'
import { processApiError } from 'helpers/utils'

const handleUpdateEntryFlag = async (entryId, flags) => {
  await securedApi
    .put(`/api/v1/widget_logbook_entries/control`, {
      id: entryId,
      widget_logbook_entry: { flags: flags },
    })
    .then((response) => {})
    .catch((error) => {
      processApiError(error)
    })
}

const renderCheckbox = (entry, flags, setValue, field, watch) => {
  const entryId = entry.id
  const entryIsNew = entry.id === undefined
  const key = `${entryId}-flags-${field}`

  const values = watch('flags')

  const handleChange = async (e) => {
    const updatedFlags = {
      ...flags,
      [field]: e.target.checked,
    }
    setValue('flags', updatedFlags)

    // update API immediately if existing entry
    if (!entryIsNew) {
      await handleUpdateEntryFlag(entryId, updatedFlags)
    }
  }

  return (
    <GridItem key={key}>
      <Checkbox
        isChecked={(values && values[field]) || false}
        onChange={handleChange}
        colorScheme="brand"
        width="100%"
        size="sm"
      >
        {field}
      </Checkbox>
    </GridItem>
  )
}

const InputStyleFlags = ({ entry, logbook, ...props }) => {
  const { register, getValues, setValue, watch } = useFormContext()

  // form values will be empty on initialise, so use values from entry in that case
  // otherwise use the form values as that is where latest selected value is stored
  const formValues = getValues()?.flags
  const flags = formValues || entry.flags || {}

  const {
    onChangeFlags: onChange,
    onBlurFlags: onBlur,
    nameFlags: name,
    refFlags: ref,
  } = register('flags', { value: flags })

  // current set of flags for this logbook
  const flagTypes = Object.keys(logbook.entry_flag_types).filter(
    (key) => logbook.entry_flag_types[key] === true
  )

  // include any old legacy flags for this entry in the list at the end
  const legacyFlagTypes = Object.keys(flags)
  const leftoverTypes = legacyFlagTypes.filter((x) => !flagTypes.includes(x))

  // if logbook has no flag types (and entry has no flags) hide flags section
  const anyTypes = !!flagTypes.length || !!leftoverTypes.length

  return (
    anyTypes && (
      <Box ml="2">
        <Grid templateColumns={'repeat(1, 1fr)'} gap={1} mt="2" mb="4">
          {flagTypes.map((flagType) => renderCheckbox(entry, flags, setValue, flagType, watch))}
          {leftoverTypes.map((flagType) => renderCheckbox(entry, flags, setValue, flagType, watch))}
        </Grid>
      </Box>
    )
  )
}

export default InputStyleFlags
