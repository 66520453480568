import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Image,
  useDisclosure,
} from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

import { PlanApplyModal } from 'domain/Plans/components/PlanModals'

const PlanDetails = ({ isOpen, onClose, selectedPlan }) => {
  const {
    isOpen: isApplyModalOpen,
    onOpen: onApplyModalOpen,
    onClose: applyModalClose,
  } = useDisclosure()

  const { data, isLoading, hasError } = useQuery(`api/v1/training_plans/${selectedPlan}`)

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{data.title}</DrawerHeader>

          <DrawerBody>
            <Stack spacing="4">
              <Box
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
              <Image
                alt="Training plan"
                src={data.image_plan}
                //   objectFit="cover"
                //   objectPosition="center"
                //   maxH={{ base: 'sm', md: 'lg' }}
                //   width="full"
              />

              <Box
                dangerouslySetInnerHTML={{
                  __html: data.extra,
                }}
              />
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={onApplyModalOpen}>
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <PlanApplyModal
        planName={data.title}
        planCode={data.lookup_code}
        isOpen={isApplyModalOpen}
        onClose={applyModalClose}
      />
    </>
  )
}
export default PlanDetails
