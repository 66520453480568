import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { revalidateLiveQueries } from 'helpers/swrConfig'
import { processApiError } from 'helpers/utils'

import Notify from 'components/Notification'
import DatePicker from 'components/DatePicker/DatePicker'

const ClientDate = ({ id, isOpen, onClose }) => {
  const [startDate, setStartDate] = useState(new Date())
  const { handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    securedApi
      .put(`api/v1/coaching/connections/${id}`, {
        start_date: startDate, //TODO timezone conversions
      })
      .then(() => {
        revalidateLiveQueries()
        Notify({ content: 'Start date has been updated', type: 'success' })
      })
      .catch((error) => processApiError(error))
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Start date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center mt="4">
              <DatePicker
                placeholderText="Select date"
                dateFormat="d/MM/yyyy"
                onChange={(date) => setStartDate(date)}
                inline
              />
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" type="submit" isLoading={loading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default ClientDate
