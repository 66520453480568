import { NavLink } from 'react-router-dom'
import {
  Box,
  Container,
  Spacer,
  Text,
  Stack,
  Card,
  CardHeader,
  Heading,
  Link,
} from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import HeaderDivider from 'components/General/HeaderDivider'

import AddTemplate from './Components/AddTemplate'

const Templates = () => {
  const { data, isLoading, hasError } = useQuery(`api/v1/templated_schedules`)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (hasError) {
    return <Error />
  }

  const zeroState = !Object.keys(data).length

  const renderTemplates = ({ id, name }) => (
    <Link
      key={id}
      as={NavLink}
      to={`/coaching/template/${id}`}
      border="1px"
      borderColor="transparent"
      _hover={{ borderColor: 'brand.500', cursor: 'pointer' }}
    >
      <Card rounded="0">
        <CardHeader>
          <Heading size="sm">{name}</Heading>
        </CardHeader>
      </Card>
    </Link>
  )

  return (
    <Container maxW="container.lg" paddingBottom={20}>
      <Box display="flex" mt="6" minHeight="100px" alignItems="center">
        <Spacer />
        <AddTemplate />
      </Box>
      <Box display="block" margin="0 auto" width="100%" maxW="600px">
        <HeaderDivider heading="Templates" />
        {zeroState && (
          <Text fontSize="sm" textAlign="center">
            No templates found
          </Text>
        )}
        <Stack spacing="4">{data.map((template) => renderTemplates(template))}</Stack>
      </Box>
    </Container>
  )
}

export default Templates
