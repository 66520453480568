import React, { useState } from 'react'
import { Box, Flex, Text, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { ChartContainer, ChartHeader, MeasureLineChart, WeightChart, NoData } from './CommonMeasure'
import LastXDays from './CommonMetrics'
import { Info } from 'react-feather'

import { tzSafeDateFormatISODate } from 'helpers/utils'

import { useDemoData } from 'hooks'

import { getDataValues } from 'helpers/charts'

import DailyMetricsModal from 'domain/DailyMetrics/DailyMetricsModal'

const MetricDaily = ({ id, data, annotations, setTriggerRefresh }) => {
  const demoData = useDemoData((state) => state.demoData)
  const [biometricsOpen, setBiometricsOpen] = useState(false)
  const [date, setDate] = useState(null)

  const handlePointClick = (fn, event) => {
    if (demoData) {
      return null
    }

    if (setTriggerRefresh !== undefined) {
      const { date_value } = event.payload
      setDate(tzSafeDateFormatISODate(date_value))
      setBiometricsOpen(true)
    }
  }

  const refresh = () => {
    setTriggerRefresh(true)
    setBiometricsOpen(false)
  }

  const renderGraph = (item) => {
    const { x_values } = getDataValues(item.data)

    var weight = item.biometric_name.toLowerCase() === 'weight'

    if (x_values.length) {
      if (weight) {
        return (
          <>
            <Box>
              <Flex justifyContent="flex-end" mr={4}>
                <ChakraTooltip
                  label={
                    <Text textAlign="center">
                      Points are recorded values
                      <br />
                      Line is the average of the previous 7 days
                    </Text>
                  }
                >
                  <Info size="18" />
                </ChakraTooltip>
              </Flex>
              <WeightChart
                item={item}
                annotations={annotations}
                handlePointClick={handlePointClick}
              />
            </Box>
          </>
        )
      } else {
        return (
          <MeasureLineChart
            item={item}
            annotations={annotations}
            handlePointClick={handlePointClick}
          />
        )
      }
    } else {
      return <NoData />
    }
  }

  return (
    <ChartContainer key={data.biometric_id}>
      <ChartHeader title={data.biometric_name} />
      <LastXDays />
      {renderGraph(data)}
      {biometricsOpen && (
        <DailyMetricsModal
          date={date}
          open={biometricsOpen}
          onClose={refresh}
          highlightAttributeId={data.biometric_id}
        />
      )}
    </ChartContainer>
  )
}

export default MetricDaily
