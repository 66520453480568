import React, { useRef } from 'react'
import { Box, Icon, Spacer, Text } from '@chakra-ui/react'
import { Move } from 'react-feather'

import { useUserPreferences } from 'hooks'
import { shortEnglishHumanizer } from 'helpers/duration'
import CategoryBlock from 'domain/Workouts/CategoryBlock'

import { useTemplateActivityModal } from '../hooks'

const TemplateActivityDetails = ({ id, name, categoryHexColour, description, duration }) => {
  const finalRef = useRef()

  const setTemplateActivityModalOpen = useTemplateActivityModal(
    (state) => state.setTemplateActivityModalOpen
  )
  const setTemplateActivityModalWorkoutId = useTemplateActivityModal(
    (state) => state.setTemplateActivityModalWorkoutId
  )

  const compactView = useUserPreferences((state) => state.compactView)

  const handleOpen = () => {
    setTemplateActivityModalWorkoutId(id)
    setTemplateActivityModalOpen()
  }

  return (
    <Box display="flex" onClick={handleOpen} ref={finalRef}>
      <CategoryBlock colour={categoryHexColour} />
      <Box display="flex" flexDirection="row" width="100%" p="2">
        <Box width="100%">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Text fontSize="small" wordBreak="break-word">
              {name}
            </Text>
            <Spacer />
          </Box>
          <Box>
            {compactView && (
              <Box>
                <Text fontSize="small" color="gray.500" mb="1">
                  {description}
                </Text>
              </Box>
            )}
            <Box>
              {duration > 0 && (
                <Text fontSize="x-small" color="" fontWeight="semibold">
                  {shortEnglishHumanizer(duration)}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Spacer />
        <Icon as={Move} color="gray.400" strokeWidth="1px" w="2" h="2" ml="1" />
      </Box>
    </Box>
  )
}

export default TemplateActivityDetails
