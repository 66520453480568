import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Image, Heading, Box, Container, Flex, Link, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import securedApi from 'backend/axios'

import { useStateManager, useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'
import { FormHeading } from 'components/Forms'
import Notify from 'components/Notification'

import { PasswordFields, SignUpButton } from 'domain/Auth/Elements'

import CSLogo from 'domain/Auth/assets/CSLogo-2.png'

const schema = z
  .object({
    password: z.string().min(6, { message: 'Required - must have at least 6 characters' }),
    password_confirmation: z.string().min(6, { message: 'Required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const PurchaseLandingExternal = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const query = `api/v1/purchases/landing_external?cs_id=${id}`
  const { data, isLoading, hasError } = useQuery(query)
  const [loading, setLoading] = useState(false)
  const { clearAll, setSignin, setAccountStatus } = useStateManager()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  if (hasError) return <Error />

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { used, user } = data

  const onSubmit = ({ password, password_confirmation }) => {
    setLoading(true)
    securedApi
      .post('/signup_membership', {
        signup: {
          id: user.id,
          password,
          password_confirmation,
        },
      })
      .then((response) => {
        signUpSuccessful(response)
      })
      .catch((error) => {
        signUpFailed(error)
      })
  }

  const signUpSuccessful = (response) => {
    if (!response.data.csrf) {
      signUpFailed(response)
      return
    }

    setSignin(response.data)
    setAccountStatus(response.data.user)

    const { id, name, email, plan } = response.data.user

    window.analytics.identify(id, { name, email, plan })

    navigate('/welcome-climb-strong')
  }

  const signUpFailed = (error) => {
    const errorContent = error.response?.data?.error || 'Error signing up. Please try again.'
    Notify({
      content: errorContent,
      type: 'error',
    })

    setLoading(false)

    clearAll()
  }

  const MainFlex = ({ children }) => {
    return (
      <Flex
        width="100%"
        py="10"
        px="4"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {children}
      </Flex>
    )
  }

  if (used) {
    return (
      <>
        <Container py={{ base: '4', md: '6' }} maxWidth="container.lg">
          <Text textAlign="center">
            This link has already been used to sign up after purchasing a plan.
          </Text>
          <Text textAlign="center">
            Please{' '}
            <Link as={NavLink} color="brand.500" fontWeight="bold" to={'/sign-in'}>
              sign in
            </Link>{' '}
            or{' '}
            <Link
              color="brand.500"
              fontWeight="bold"
              href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
            >
              contact support
            </Link>{' '}
            if you require additional assistance.
          </Text>
        </Container>
      </>
    )
  }

  return (
    <MainFlex>
      <Box maxWidth="400px" width="100%">
        <Stack spacing="3" align="center">
          <Flex align="center" justify="center" bg="#333" p="4">
            <Image src={CSLogo} maxW="150px" alt="Climb Strong Logo" />
          </Flex>

          <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
            Purchase Successful
          </Heading>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormHeading>
            <Text>Complete your membership by entering a password.</Text>
          </FormHeading>
          <Stack mt="8">
            <PasswordFields {...{ register, errors }} />
            <SignUpButton {...{ loading }} label="Complete membership" />
          </Stack>
        </form>
      </Box>
    </MainFlex>
  )
}

export default PurchaseLandingExternal
